import {
  Box,
  Button,
  Divider,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useMutation } from "urql";
import { gqlTourMutationUpdate } from "../../../api/mutations/tour";
import { restClient } from "../../../api/rest.api";
import { InputField } from "../../../components/forms/inputField";
import { MultiSwitchField } from "../../../components/forms/multiSwitchField";
import { SelectField } from "../../../components/forms/selectField";
import { TagsField } from "../../../components/forms/tagsField";
import { MediaUpload } from "../../../components/mediaUpload";
import { Language, Tag } from "../../../entities/reference";
import { isFrozenStatus, TourDetail } from "../../../entities/tour";
import { tourSchema } from "../../../schemas/tour";
import { fullTour } from "../../../state/fullTour";
import { referenceData } from "../../../state/reference";
import { capitalizeFirstLetter } from "../../../utils/strings";
import { TourWrapper } from "../wrapper";
import { LanguageDetails } from "./components/languageDetails";
import { tokenManagement } from "../../../auth/token";
import { User } from "../../../entities/user";
import { restAdminClient } from "../../../api/admin.api";

const TAGS_SEPARATOR = ";";
export const DEFAULT_LANGUAGE_CODE = "EN";

export const Details = () => {
  const { tourId } = useParams();
  const [fullTourValue, setFullTourValue] = useRecoilState(fullTour);
  const [tourLanguages, setTourLanguages] = useState<TourDetail[]>(
    fullTourValue.tourDetails || []
  );
  const [users, setUsers] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    setTourLanguages(fullTourValue.tourDetails || []);
  }, [fullTourValue]);

  const [{ fetching: executingUpdate }, executeMutationUpdateTour] =
    useMutation(gqlTourMutationUpdate);
  const { languages, tags } = useRecoilValue(referenceData);

  const fetchUsers = () => {
    restAdminClient
      .getUsers()
      .then((users) => {
        setUsers(
          users.map((user: User) => ({
            id: user.id,
            name: user.email,
          }))
        );
      })
      .catch(() => {
        setUsers([]);
      });
  };

  useEffect(() => {
    if (tokenManagement.isAdmin()) {
      fetchUsers();
    }
  }, []);

  const getTourLanguage = (lang: string): TourDetail => {
    return (
      tourLanguages.find(
        (tourLanguage: TourDetail) => tourLanguage.languageCode === lang
      ) || {
        languageCode: lang,
        name: "",
        description: "",
      }
    );
  };

  const setTourLanguage = (tourDetail: TourDetail): void => {
    let found = false;
    const newTourLanguages = structuredClone(tourLanguages).map(
      (tourLanguage: TourDetail) => {
        if (tourLanguage.languageCode === tourDetail.languageCode) {
          found = true;
          return tourDetail;
        }
        return tourLanguage;
      }
    );

    if (!found) {
      newTourLanguages.push(tourDetail);
    }

    setTourLanguages(newTourLanguages);
  };

  const getLanguageDropdownOptions = (selectedLanguages: string[]) => {
    return selectedLanguages.map((selectedLanguage: string) => ({
      id: selectedLanguage,
      name:
        languages.find(({ code }: Language) => code === selectedLanguage)
          ?.name || selectedLanguage,
    }));
  };

  const uploadScreenshotMap = (
    files: File[],
    callback: (file: File, filename: string, succeed: boolean) => void
  ) => {
    restClient.uploadMedia(
      tourId!,
      files,
      {
        location: "tour.screenshot",
      },
      callback
    );
  };

  const removeScreenshotMap = (
    filename: string,
    callback: (filename: string, succeed: boolean) => void
  ) => {
    restClient.deleteMedia(
      tourId!,
      filename,
      {
        location: "tour.screenshot",
      },
      callback
    );
  };

  return (
    <TourWrapper title="Details">
      <Formik
        initialValues={{
          name: fullTourValue.name,
          price: fullTourValue.price?.price,
          tourDuration: fullTourValue.tourDuration || "",
          tags: fullTourValue.tags?.join(TAGS_SEPARATOR) || "",
          languagesCode:
            fullTourValue.languagesCode?.join(TAGS_SEPARATOR) ||
            DEFAULT_LANGUAGE_CODE,
          defaultLanguageCode:
            fullTourValue.defaultLanguageCode?.toUpperCase() ||
            DEFAULT_LANGUAGE_CODE,
          authorId: fullTourValue.authorId,
          externalUrlTour: fullTourValue.externalUrlTour,
        }}
        onSubmit={({
          name,
          price,
          tourDuration,
          tags,
          defaultLanguageCode,
          externalUrlTour,
          languagesCode,
          authorId,
        }) => {
          executeMutationUpdateTour({
            updateTourInput: {
              id: fullTourValue.id,
              name,
              tourDuration,
              externalUrlTour,
              price: {
                currency: "EUR",
                price: +(price || 0),
              },
              tags: tags.split(TAGS_SEPARATOR),
              authorId,
              defaultLanguageCode,
              languagesCode: languagesCode.split(TAGS_SEPARATOR),
              tourDetails: tourLanguages
                .filter((tourLanguage: TourDetail) =>
                  languagesCode.includes(tourLanguage.languageCode)
                )
                .map(({ name, description, languageCode }: TourDetail) => ({
                  name,
                  description,
                  languageCode,
                })),
            },
          }).then(() => {
            setFullTourValue({
              ...structuredClone(fullTourValue),
              name,
              tourDuration,
              externalUrlTour,
              price: {
                currency: "EUR",
                price: String(price || 0),
              },
              tags: tags.split(TAGS_SEPARATOR),
              authorId,
              languagesCode: languagesCode.toUpperCase().split(TAGS_SEPARATOR),
              tourDetails: tourLanguages.filter((tourLanguage: TourDetail) =>
                languagesCode.includes(tourLanguage.languageCode.toUpperCase())
              ),
            });
          });
        }}
        validationSchema={tourSchema}
      >
        {({ values }) => (
          <Form>
            <HStack
              padding={3}
              alignItems="flex-start"
              maxWidth={{
                base: "100%",
                md: "95%",
              }}
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              <Box
                maxWidth={{
                  base: "100%",
                  md: "40%",
                }}
                width={{
                  base: "100%",
                  md: "40%",
                }}
                minWidth="300px"
                marginRight={4}
              >
                <HStack>
                  <Text fontSize="sm" fontWeight="bold">
                    City:
                  </Text>
                  <Text fontSize="sm">{fullTourValue.city?.name}</Text>
                </HStack>
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="name"
                  label="Name"
                  description="This name is used internally and not shown to the users"
                  required={true}
                />
                {tokenManagement.isAdmin() && (
                  <>
                    <Divider marginTop={4} marginBottom={4} />
                    <SelectField
                      name="authorId"
                      label="Author"
                      description="If you want to use another user as author select it"
                      required={true}
                      options={users}
                      defaultValue={fullTourValue.authorId}
                    />
                  </>
                )}
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="tourDuration"
                  label="Tour Duration (In Minutes)"
                />
                <Divider marginTop={4} marginBottom={4} />
                {tokenManagement.isAdmin() && (
                  <>
                    <InputField
                      name="externalUrlTour"
                      label="External Tour URL"
                    />
                    <Divider marginTop={4} marginBottom={4} />
                  </>
                )}
                <InputField
                  name="price"
                  label="Tour Price"
                  leftInputLabel="EUR"
                />
                <Divider marginTop={4} marginBottom={4} />
                <TagsField
                  name="tags"
                  label="Tags"
                  description="Type the tag and press enter"
                  suggestion={tags
                    .map((t: Tag) => t.name)
                    .filter(
                      (tagName: string) => !values.tags.includes(tagName)
                    )}
                />
                <Divider marginTop={4} marginBottom={4} />
                <MultiSwitchField
                  name="languagesCode"
                  label="Available Languages"
                  description="Select as many languages as you want to prepare this tour"
                  options={languages.map((lang: Language) => lang.code)}
                />
                <Divider marginTop={4} marginBottom={4} />
                <SelectField
                  label="Default Language"
                  name="defaultLanguageCode"
                  defaultValue={fullTourValue.defaultLanguageCode?.toUpperCase()}
                  options={getLanguageDropdownOptions(
                    values.languagesCode.split(TAGS_SEPARATOR) || [
                      DEFAULT_LANGUAGE_CODE,
                    ]
                  )}
                />
                <Divider marginTop={4} marginBottom={4} />
                <MediaUpload
                  label="Map Screenshot"
                  multiple={false}
                  files={
                    fullTourValue.screenshotMap
                      ? [fullTourValue.screenshotMap]
                      : []
                  }
                  uploadFiles={uploadScreenshotMap}
                  onRemoveFile={removeScreenshotMap}
                  type="image"
                />
              </Box>
              <Tabs
                flex="auto"
                borderLeft="1px solid #ccc"
                paddingLeft={4}
                alignSelf="stretch"
                maxWidth="100%"
              >
                <Text>Translations</Text>
                <TabList>
                  {(values.languagesCode || DEFAULT_LANGUAGE_CODE)
                    .split(TAGS_SEPARATOR)
                    .map((lang: string) => (
                      <Tab key={lang}>
                        {capitalizeFirstLetter(
                          languages.find(
                            (l: Language) => l.code === lang.toUpperCase()
                          )?.name || lang
                        )}
                      </Tab>
                    ))}
                </TabList>

                <TabPanels>
                  {(values.languagesCode || DEFAULT_LANGUAGE_CODE)
                    .split(TAGS_SEPARATOR)
                    .map((lang: string) => (
                      <TabPanel key={lang} maxWidth="100%">
                        <LanguageDetails
                          tourId={fullTourValue.id || ""}
                          setTourLanguage={setTourLanguage}
                          tourLanguage={getTourLanguage(lang)}
                        />
                      </TabPanel>
                    ))}
                </TabPanels>
              </Tabs>
            </HStack>
            <Button
              position="absolute"
              bottom="15px"
              right="15px"
              disabled={
                executingUpdate || isFrozenStatus(fullTourValue.status!)
              }
              type="submit"
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </TourWrapper>
  );
};
