import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context";

interface MiddlewareRouteProps {
  children: ReactNode;
}

export const PublicRoute = ({ children }: MiddlewareRouteProps) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  });

  return <>{children}</>;
};

export const PrivateRoute = ({ children }: MiddlewareRouteProps) => {
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  });

  return <>{children}</>;
};
