import { iconFactory } from "./iconFactory";

export const iconDefault = iconFactory(
  "https://img.icons8.com/office/80/000000/marker.png"
);

export const iconPerson = iconFactory(
  "https://img.icons8.com/color/48/000000/user-location.png"
);

export const iconPin = iconFactory(
  "https://img.icons8.com/fluency/96/000000/map-pin.png"
);

export const iconPinBlack = iconFactory(
  "https://img.icons8.com/ios-glyphs/90/000000/map-pin.png"
);

export const iconCamera = iconFactory(
  "https://img.icons8.com/fluency/96/000000/point-of-interest.png"
);

export const iconCameraBlack = iconFactory(
  "https://img.icons8.com/ios-filled/100/000000/point-of-interest.png"
);

export const iconDollar = iconFactory(
  "https://img.icons8.com/stickers/100/000000/dollar-place-marker.png"
);

export const iconDollarBlack = iconFactory(
  "https://img.icons8.com/sf-regular-filled/96/000000/dollar-place-marker.png"
);

export const iconMarkerSquare = iconFactory(
  "https://img.icons8.com/stickers/100/000000/where.png"
);

export const iconRestaurant = iconFactory(
  "https://img.icons8.com/parakeet/96/000000/restaurant-building.png"
);

export const iconRestaurantBlack = iconFactory(
  "https://img.icons8.com/sf-regular-filled/96/000000/restaurant-building.png"
);

export const availableIcons: { id: string; label: string }[] = [
  {
    id: "iconDefault",
    label: "Default Icon",
  },
  {
    id: "iconPerson",
    label: "Person Icon",
  },
  {
    id: "iconPin",
    label: "Pin Icon",
  },
  {
    id: "iconPinBlack",
    label: "Black Pin Icon",
  },
  {
    id: "iconCamera",
    label: "Camera Icon",
  },
  {
    id: "iconCameraBlack",
    label: "Black Camera Icon",
  },
  {
    id: "iconDollar",
    label: "Dollar Icon",
  },
  {
    id: "iconDollarBlack",
    label: "Black Dollar Icon",
  },
  {
    id: "iconMarkerSquare",
    label: "Marker Square Icon",
  },
  {
    id: "iconRestaurant",
    label: "Restaurant Icon",
  },
  {
    id: "iconRestaurantBlack",
    label: "Black Restaurant Icon",
  },
];

export type IconTypes =
  | "iconDefault"
  | "iconPerson"
  | "iconPin"
  | "iconPinBlack"
  | "iconCamera"
  | "iconCameraBlack"
  | "iconDollar"
  | "iconDollarBlack"
  | "iconMarkerSquare"
  | "iconRestaurant"
  | "iconRestaurantBlack";
