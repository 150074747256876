import {
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { restAdminClient } from "../../../api/admin.api";
import { tokenManagement } from "../../../auth/token";
import { Tour, TourStatus } from "../../../entities/tour";

import { BasePrivate } from "../../basePrivate";
import { StatusSelect } from "./components/statusSelect";
import { TourPopup } from "./components/tourPopup";

export const AdminTours = () => {
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState<Tour[]>([]);
  const [selectedTour, setSelectedTour] = useState<string | null>(null);

  const navigate = useNavigate();

  if (!tokenManagement.isAdmin()) {
    navigate("/dashboard");
  }

  useEffect(() => {
    restAdminClient
      .getTours()
      .then((tours) => {
        setTours(tours);
      })
      .catch(() => {
        setTours([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onChangeStatus =
    (id: string) => (evt: ChangeEvent<HTMLSelectElement>) => {
      restAdminClient.updateTourStatus(
        id,
        evt.currentTarget.value as TourStatus
      );
    };
  return (
    <BasePrivate title="Users (Admin)">
      <VStack padding={3} alignItems="flex-start">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Author</Th>
                <Th>Status</Th>
                <Th>View</Th>
                <Th>Created At</Th>
                <Th>Updated At</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading && <Spinner />}
              {!loading &&
                tours.map((tour) => (
                  <Tr key={tour.id}>
                    <Td>{tour.name}</Td>
                    <Td>{tour.author?.email || tour.authorId}</Td>
                    <Td>
                      <StatusSelect
                        selected={tour.status}
                        onChange={onChangeStatus(tour.id)}
                      />
                    </Td>
                    <Td>
                      <IconButton
                        aria-label={tour.id}
                        icon={<FaEye />}
                        onClick={() => setSelectedTour(tour.id)}
                      />
                    </Td>
                    <Td>{tour.createdAt}</Td>
                    <Td>{tour.updatedAt}</Td>
                  </Tr>
                ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Name</Th>
                <Th>Author</Th>
                <Th>Status</Th>
                <Th>View</Th>
                <Th>Created At</Th>
                <Th>Updated At</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <TourPopup
          tourId={selectedTour}
          onClose={() => setSelectedTour(null)}
        />
      </VStack>
    </BasePrivate>
  );
};
