import { LatLng } from "leaflet";
import { PointDetails } from "../../../../../entities/pointDetails";
import { PoiMarker } from "./poiMarker";

interface PoiProps {
  dataPoints: PointDetails[];
  onClickEditPoi: (index: number) => void;
}

export const PoiDrawer = ({ dataPoints, onClickEditPoi }: PoiProps) => {
  const onClickEdit = (index: number) => () => onClickEditPoi(index);
  return (
    <>
      {dataPoints
        .filter((dataPoint: PointDetails) => dataPoint.location?.lat)
        .map((dataPoint: PointDetails, index: number) => {
          const newLatLng = new LatLng(
            parseFloat(dataPoint.location!.lat),
            parseFloat(dataPoint.location!.lng)
          );

          return (
            <PoiMarker
              key={index}
              latlng={newLatLng}
              onClick={onClickEdit(index)}
              icon={dataPoint.icon}
            />
          );
        })}
    </>
  );
};
