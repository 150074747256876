import {
  Button,
  HStack,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { restAdminClient } from "../../../api/admin.api";
import { tokenManagement } from "../../../auth/token";
import { Country } from "../../../entities/reference";

import { BasePrivate } from "../../basePrivate";

export const AdminCountries = () => {
  const [loading, setLoading] = useState(true);
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [error, setError] = useState("");
  const [countries, setCountries] = useState<Country[]>([]);

  const navigate = useNavigate();

  if (!tokenManagement.isAdmin()) {
    navigate("/dashboard");
  }

  const fetchCountries = () =>
    restAdminClient
      .getCountries()
      .then((countries) => {
        setCountries(countries);
      })
      .catch(() => {
        setCountries([]);
      })
      .finally(() => {
        setLoading(false);
      });

  useEffect(() => {
    fetchCountries();
  }, []);

  const onChangeCountryName = (evt: ChangeEvent<HTMLInputElement>) => {
    setCountryName(evt.currentTarget.value);
  };

  const onChangeCountryCode = (evt: ChangeEvent<HTMLInputElement>) => {
    setCountryCode(evt.currentTarget.value);
  };

  const addCountry = () => {
    if (countryName.length && countryCode.length) {
      setError("");
      restAdminClient
        .addCountry(countryName, countryCode)
        .then(() => {
          setCountryCode("");
          setCountryName("");
          fetchCountries();
        })
        .catch((err) => {
          setError(
            err.response.data.message || "There was an error adding the country"
          );
        });
    }
  };

  return (
    <BasePrivate title="Countries (Admin)">
      <VStack padding={3} alignItems="flex-start">
        <HStack>
          <Input
            onChange={onChangeCountryCode}
            value={countryCode}
            placeholder="Code. Ie: ES | NL | AR"
          />
          <Input
            onChange={onChangeCountryName}
            value={countryName}
            placeholder="Name"
          />
          <Button onClick={addCountry} disabled={!countryName.length}>
            Add
          </Button>
        </HStack>
        {error.length && (
          <Text color="red" fontSize="xs">
            {error}
          </Text>
        )}
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading && <Spinner />}
              {!loading &&
                countries.map((country) => (
                  <Tr key={country.id}>
                    <Td>{country.id}</Td>
                    <Td>{country.name}</Td>
                  </Tr>
                ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </VStack>
    </BasePrivate>
  );
};
