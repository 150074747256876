import { useCallback, useEffect, useState } from "react";
import {
  Tooltip,
  HStack,
  VStack,
  Switch,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { LatLng } from "leaflet";
import { FaRecordVinyl } from "react-icons/fa";
import "./recordMenu.css";

const HIGH_ACCURACY = true;
const MAX_CACHE_AGE_MILLISECOND = 30000;
const MAX_NEW_POSITION_MILLISECOND = 5000;

const trackOptions = {
  enableHighAccuracy: HIGH_ACCURACY,
  maximumAge: MAX_CACHE_AGE_MILLISECOND,
  timeout: MAX_NEW_POSITION_MILLISECOND,
};

interface RecordMenuProp {
  enabledLocation: boolean;
  enabledRecording: boolean;
  setEnabledRecording: (enabledRecording: boolean) => void;
  onAddRouteStopClick: (_: any, location: LatLng) => void;
}

export const RecordMenu = ({
  enabledLocation,
  enabledRecording,
  setEnabledRecording,
  onAddRouteStopClick,
}: RecordMenuProp) => {
  const [trackTime, setTrackTime] = useState(3); // Time in seconds
  const [lastTime, setLastTime] = useState<number | null>(null);
  const [lastPosition, setLastPosition] = useState<LatLng | null>(null);

  const toogleRecording = () => {
    setEnabledRecording(!enabledRecording);
  };

  const onErrorCb = useCallback((err: GeolocationPositionError) => {
    console.error(err);
  }, []);

  const setLastStatusValue = useCallback(
    (lastTime: number, lastPosition: LatLng) => {
      setLastTime(lastTime);
      setLastPosition(lastPosition);
    },
    [setLastTime, setLastPosition]
  );

  const onSuccessCb = useCallback(
    (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      const currentTime = new Date().getTime();
      const newPosition = new LatLng(latitude, longitude);
      if (lastTime && currentTime - lastTime < trackTime * 1000) {
        setLastStatusValue(lastTime, newPosition);
        return;
      }

      if (
        !lastPosition ||
        (latitude !== lastPosition.lat && longitude !== lastPosition.lng)
      ) {
        setLastStatusValue(currentTime, newPosition);
        onAddRouteStopClick(null, newPosition);
      }
    },
    [lastPosition, lastTime, trackTime, setLastStatusValue, onAddRouteStopClick]
  );

  useEffect(() => {
    if (enabledRecording) {
      const watcher = navigator.geolocation.watchPosition(
        onSuccessCb,
        onErrorCb,
        trackOptions
      );
      return () => navigator.geolocation.clearWatch(watcher);
    }
  }, [trackTime, onSuccessCb, onErrorCb, enabledRecording]);

  const format = (val: number) => `${val}s`;
  const parse = (val: string) => parseInt(val.replace("s", ""), 10);

  const label = !navigator.geolocation
    ? "Geolocation is not supported by your browser"
    : !enabledLocation
    ? "Enable location to record your track"
    : "Track your path";

  return (
    <VStack
      position="absolute"
      right="15px"
      bottom="80px"
      zIndex={999}
      justifyContent="space-between"
      alignContent="center"
      alignSelf="center"
      borderTopRadius={5}
    >
      <Tooltip label={label} placement="top">
        <HStack
          background="#FFFB"
          paddingTop="4px"
          paddingBottom="4px"
          paddingLeft="8px"
          paddingRight="8px"
        >
          {enabledRecording && (
            <NumberInput
              size="xs"
              onChange={(valueString: string) =>
                setTrackTime(parse(valueString))
              }
              value={format(trackTime)}
              min={1}
              max={50}
            >
              <NumberInputField width={20} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
          <FaRecordVinyl
            className={enabledRecording ? "recording" : "recordingStopped"}
          />
          <Switch
            id="enableRecording"
            isChecked={enabledRecording}
            disabled={!enabledLocation}
            onChange={toogleRecording}
          />
        </HStack>
      </Tooltip>
    </VStack>
  );
};
