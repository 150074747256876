export const gqlTourMutationAdd = `
  mutation CreateTour($createTourInput: CreateTourInput!) {
    createTour(createTourInput: $createTourInput) {
      name
      cityId
    }
  }
`;

export const gqlTourMutationUpdate = `
  mutation TourUpdate($updateTourInput: UpdateTourInput!) {
    updateTour(updateTourInput: $updateTourInput)
  }
`;

export const gqlTourMutationUpdateStatus = `
  mutation TourStatusUpdate($updateTourStatusInput: UpdateTourStatusInput!) {
    updateTourStatus(updateTourStatusInput: $updateTourStatusInput)
  }
`;

export const gqlTourMutationRemove = `
  mutation RemoveTour($removeTourId: String!) {
    removeTour(id: $removeTourId)
  }
`;
