import { PointDetails } from "./pointDetails";
import { City } from "./reference";
import { User } from "./user";

export type TourStatus =
  | "DRAFT"
  | "PREREADY"
  | "PREPUBLISHED"
  | "READY"
  | "PUBLISHED"
  | "REJECTED";

export const TourStatusValues = {
  DRAFT: "DRAFT",
  PREREADY: "PREREADY",
  PREPUBLISHED: "PREPUBLISHED",
  READY: "READY",
  PUBLISHED: "PUBLISHED",
  REJECTED: "REJECTED",
};

export const FrozenStatusValues = {
  DRAFT: false,
  PREREADY: false,
  PREPUBLISHED: true,
  READY: true,
  PUBLISHED: true,
  REJECTED: false,
};

export const isFrozenStatus = (status?: TourStatus) =>
  status ? FrozenStatusValues[status] : false;

export interface TourDetail {
  languageCode: string;
  name: string;
  description: string;
  images?: string[];
  introductionVideo?: string;
}

interface Price {
  currency: string;
  price: string;
}

interface TourStats {
  pending: number;
  played: number;
  completed: number;
  cancelled: number;
  sales: number;
  cancelledSales: number;
}

export interface Tour {
  id: string;
  name: string;
  externalUrlTour?: string;
  price?: Price;
  offerPrice?: Price;
  discount?: number;
  authorId?: string;
  author?: User;
  ownerId?: string;
  owner?: User;
  status: TourStatus;
  languagesCode?: string[];
  defaultLanguageCode: string;
  cityId: string;
  tourDetails?: TourDetail[];
  tourDuration?: string;
  tourDistance?: string;
  screenshotMap?: string;
  tags?: string[];
  routePoints?: PointDetails[];
  stopPoints?: PointDetails[];
  pointsOfInterest?: PointDetails[];
  createdAt: string;
  updatedAt?: string;
  city: City;
  stats?: TourStats;
  score: number;
  totalVotes: number;
  totalVisits: number;
}
