import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Tag,
  Text,
} from "@chakra-ui/react";

interface HelpPopupProps {
  isOpen: boolean;
  onClose: () => void;
}
export const HelpPopup = ({ isOpen, onClose }: HelpPopupProps) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="lg" textTransform="uppercase">
          Map Help
        </Heading>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Heading size="md" textTransform="uppercase">
              Route drawing
            </Heading>
            <Text pt="2" fontSize="sm">
              We don't use any tool to calculate the best path. Instead you ask
              you to add as many points as needed to be able to draw a proper
              route.
            </Text>
          </Box>
          <Box>
            <Heading size="sm" textTransform="uppercase">
              Why?
            </Heading>
            <Text pt="2" fontSize="sm">
              Because systems like Google tend to draw the best route based in
              real-time data, and in case of, for example having a route with a
              lot of traffic, it could suggest going through another street.
            </Text>
          </Box>
          <Box>
            <Heading size="sm" textTransform="uppercase">
              Route points name
            </Heading>
            <Text pt="2" fontSize="sm">
              The name is used as an internal reference and it won't be shown to
              the final user. Even if you won't use the route point as a STOP,
              you can set a generic name to easily identify it by clicking the
              "pencil" button.
            </Text>
            <Text pt="2" fontSize="sm">
              If you don't change the name we will show the order number
              instead.
            </Text>
          </Box>
          <Box>
            <Heading size="sm" textTransform="uppercase">
              Identify the Route Points
            </Heading>
            <Text pt="2" fontSize="sm">
              Toggle the "Show #" button to show/hide the label showing the
              numbers in the map. It will help you to identify the Route Points.
            </Text>
          </Box>
          <Box>
            <Heading size="sm" textTransform="uppercase">
              Route points colors
            </Heading>
            <Text pt="2" fontSize="sm">
              <Tag size="md" variant="solid" colorScheme="yellow"></Tag>
              Initial point.
            </Text>
            <Text pt="2" fontSize="sm">
              <Tag size="md" variant="solid" colorScheme="green"></Tag>
              Focused dot. When you move the mouse over or click to move an item
              from the list, it will change the color to green.
            </Text>
            <Text pt="2" fontSize="sm">
              <Tag size="md" variant="solid" colorScheme="purple"></Tag>
              Route points will be inserted after this route point. You can
              click the Route Point you want to use as reference and the
              checkbox in the list will be selected. Another option is to
              directly select the checkbox from the list.
            </Text>
            <Text pt="2" fontSize="sm">
              <Tag size="md" variant="solid" colorScheme="red"></Tag>
              Normal route point. Used to draw the route and the points.
            </Text>
            <Text pt="2" fontSize="sm">
              <Tag size="md" variant="solid" colorScheme="blue"></Tag>
              Last route point.
            </Text>
          </Box>
          <Box>
            <Heading size="sm" textTransform="uppercase">
              Moving a Route Point
            </Heading>
            <Text pt="2" fontSize="sm">
              1. Select the ROUTE POINT (tick the checkbox)
            </Text>
            <Text pt="2" fontSize="sm">
              2. Select the point on the map where you want to move it and click
              with the right button to open the "Context Menu"
            </Text>
            <Text pt="2" fontSize="sm">
              3. Click the option "Move Here"
            </Text>
          </Box>
          <Box>
            <Heading size="sm" textTransform="uppercase">
              Add media and text descriptions in multiple languages
            </Heading>
            <Text pt="2" fontSize="sm">
              If you want to define a STOP with information, you should click
              the "pencil" button in the list and configure the route.
            </Text>
            <Text pt="2" fontSize="sm">
              It is possible to add 1 audio, multiple images and text for all
              the selected languages in the tour.
            </Text>
          </Box>
        </Stack>
      </ModalBody>
    </ModalContent>
  </Modal>
);
