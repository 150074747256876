import { Map } from "leaflet";
import { useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";

interface MapEventsProps {
  onClick: (e: any, map: Map) => void;
  onContextMenu: (e: any) => void;
  setMap: (map: Map) => void;
}

export const MapEvents = ({
  onClick,
  onContextMenu,
  setMap,
}: MapEventsProps) => {
  const map = useMap();

  useEffect(() => {
    setMap(map);
  }, [map, setMap]);

  useMapEvents({
    click(e) {
      if (
        !(
          (e.originalEvent?.target as any).getAttribute("class") || ""
        ).includes("chakra")
      ) {
        onClick(e, map);
      }
    },
    contextmenu(e) {
      onContextMenu(e);
    },
  });
  return null;
};
