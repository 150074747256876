import { Image } from "@chakra-ui/react";
import { MediaWrapper, MediaWrapperProps } from "./wrapper";

export const MediaImage = ({
  name,
  src,
  loading,
  onClickRemove,
}: MediaWrapperProps) => {
  return (
    <MediaWrapper
      name={name}
      src={src}
      loading={loading}
      onClickRemove={onClickRemove}
    >
      <Image src={src} alt={name} width="200px" height="200px" />
    </MediaWrapper>
  );
};
