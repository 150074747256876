export type UserStatus = "PENDING" | "CONFIRMED" | "ACTIVE" | "DEACTIVATED";

export const UserStatusValues = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED", // Only valid for creators
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
};

export interface User {
  id?: string;
  name?: string;
  email?: string;
  shareEmail?: boolean;
  description?: string;
  phoneNumber?: string;
  sharePhoneNumber?: boolean;
  whatsapp?: boolean;
  instagram?: string;
  facebook?: string;
  twitter?: string;
  tripAdvisor?: string;
  website?: string;
  languages?: string;
  photo?: string;
  status?: UserStatus;
  createdAt?: string;
}
