import * as yup from "yup";

const ROUTE_POINT = "ROUTE_POINT";
const STOP = "STOP";
const AUTO_PLAY = "AUTO_PLAY";
const POINT_OF_INTEREST = "POINT_OF_INTEREST";
export const DEFAULT_POINT_TYPE = ROUTE_POINT;

export const typeRoutePointDropdownOptions = [
  {
    id: ROUTE_POINT,
    name: ROUTE_POINT,
  },
  {
    id: STOP,
    name: STOP,
  },
  {
    id: AUTO_PLAY,
    name: AUTO_PLAY,
  },
];

export const typePoiDropdownOptions = [
  {
    id: POINT_OF_INTEREST,
    name: POINT_OF_INTEREST,
  },
];

const locationSchema = yup.object().shape({
  lat: yup.string().required("Please provide a Latitude"),
  lng: yup.string().required("Please provide a Longitude"),
});

const languageDetailSchema = yup.object().shape({
  description: yup.string().when("type", {
    is: "STOP_MEDIA",
    then: () => yup.string().required(),
  }),
  audio: yup.string().when("type", {
    is: "WALKING_AUDIO",
    then: () => yup.string().required(),
  }),
  video: yup.string().when("type", {
    is: "STOP_MEDIA",
    then: () => yup.string(),
  }),
});

export const pointDetailsSchema = yup.object().shape({
  location: locationSchema,
  type: yup
    .string()
    .oneOf([AUTO_PLAY, STOP, ROUTE_POINT, POINT_OF_INTEREST])
    .default(DEFAULT_POINT_TYPE)
    .required("Please select a type"),
  name: yup.string().when("type", {
    is: (type: string) => type !== "TRACK_POINT",
    then: () => yup.string().required(),
  }),
  languages: yup.array().of(languageDetailSchema),
  images: yup
    .array()
    .of(yup.string())
    .when("type", {
      is: "STOP_MEDIA",
      then: () => yup.array().of(yup.string()),
    }),
});
