export const gqlReferenceQuery = `
  query Reference {
    countries {
      id
      name
    }
    
    cities {
      id
      name
      countryId
    }
    
    languages {
      name
      localName
      code
    }
    
    tags {
      name
    }
  }
`;
