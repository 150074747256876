import {
  Box,
  Heading,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MediaAudio } from "../../../../components/mediaUpload/medias/audio";
import { MediaVideo } from "../../../../components/mediaUpload/medias/video";
import { getMediaURL } from "../../../../components/mediaUpload/utils";
import { PointDetails } from "../../../../entities/pointDetails";
import { buildGoogleMapsURL } from "../utils";

interface RoutePointsDetailsProps {
  points: PointDetails[];
}

export const RoutePointsDetails = ({ points }: RoutePointsDetailsProps) => {
  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList flexWrap="wrap">
        {(points || []).map((routePoint, index) => (
          <Tab key={`${routePoint.location.lat}-${routePoint.location.lng}-${index}`}>
            {routePoint.name || index + 1}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {(points || []).map((routePoint, index) => (
          <TabPanel
            key={`${routePoint.location.lat}-${routePoint.location.lng}-${index}`}
          >
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Location
              </Heading>
              <Text pt="2" fontSize="sm">
                <Link
                  href={buildGoogleMapsURL(
                    routePoint.location.lat,
                    routePoint.location.lng
                  )}
                  target="_blank"
                >
                  {`[${routePoint.location.lat};${routePoint.location.lng}]`}
                </Link>
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Type
              </Heading>
              <Text pt="2" fontSize="sm">
                {routePoint.type || "-"}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Icon
              </Heading>
              {routePoint.icon || "-"}
            </Box>
            <Box>
              <Heading size="sm" textTransform="uppercase">
                Languages
              </Heading>
              <Tabs isFitted variant="enclosed">
                <TabList>
                  {(routePoint.languages || []).map((routeLanguage) => (
                    <Tab key={routeLanguage.languageCode}>
                      {routeLanguage.languageCode}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {(routePoint.languages || []).map((routeLanguage) => (
                    <TabPanel key={routeLanguage.languageCode}>
                      <Box>
                        <Heading size="xs" textTransform="uppercase">
                          Language: {routeLanguage.languageCode}
                        </Heading>
                      </Box>
                      <Box>
                        <Heading size="xs" textTransform="uppercase">
                          Description
                        </Heading>
                        <Text pt="2" fontSize="sm">
                          {routeLanguage.description}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size="xs" textTransform="uppercase">
                          Images
                        </Heading>
                        <Text pt="2" fontSize="sm">
                          {routeLanguage.images?.map((image: string) => (
                            <Image
                              alt={image}
                              src={getMediaURL(image)}
                              key={image}
                              width="300"
                              height="300"
                            />
                          ))}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size="xs" textTransform="uppercase">
                          Video
                        </Heading>
                        {routeLanguage.videos
                          ? routeLanguage.videos.map((video: string) => (
                              <MediaVideo
                                name={video}
                                src={getMediaURL(video)}
                              />
                            ))
                          : "Not Provided"}
                      </Box>
                      <Box>
                        <Heading size="xs" textTransform="uppercase">
                          Audio
                        </Heading>
                        {(routeLanguage.audio && (
                          <MediaAudio
                            name="Audio"
                            src={getMediaURL(routeLanguage.audio)}
                          />
                        )) ||
                          "Not Provided"}
                      </Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
