import { LatLng } from "leaflet";
import { Marker } from "react-leaflet";
import * as icons from "../../../../../../icons";

interface PoiMarkerProps {
  latlng: LatLng;
  onClick: () => void;
  icon?: icons.IconTypes;
}
export const PoiMarker = ({ icon, latlng, onClick }: PoiMarkerProps) => {
  return (
    <Marker
      position={latlng}
      eventHandlers={{
        click: onClick,
      }}
      icon={icon?.length ? icons[icon] : icons.iconDefault}
    ></Marker>
  );
};
