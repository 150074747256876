import {
  Link,
  VStack,
  FormControl,
  FormHelperText,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useState } from "react";
import { restClient } from "../../api/rest.api";
import { PublicRoute } from "../../auth/routeMiddlewares";
import { tokenManagement } from "../../auth/token";
import { InputField } from "../../components/forms/inputField";

import { Logo } from "../../Logo";
import { userLoginSchema } from "../../schemas/userLogin";

export const HomePage = () => {
  const [loginError, setLoginError] = useState(false);

  return (
    <PublicRoute>
      <VStack spacing={8} w={300} justifySelf="center" marginTop={4}>
        <Logo height="80px" pointerEvents="none" />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={({ email, password }, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            restClient
              .login(email, password)
              .then((accessToken: string) => {
                if (accessToken) {
                  tokenManagement.setAuthToken(accessToken);
                  window.location.href = "/dashboard";
                } else {
                  setLoginError(true);
                  setSubmitting(false);
                }
              })
              .catch(() => {
                setLoginError(true);
                setSubmitting(false);
              });
          }}
          validationSchema={userLoginSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormControl isInvalid={loginError}>
                <InputField name="email" type="email" label="Email" />
                <FormHelperText>
                  We&apos;ll never share your email
                </FormHelperText>
                <InputField name="password" type="password" label="Password" />
                <FormErrorMessage>Wrong Email or Password</FormErrorMessage>
                <Button type="submit" marginTop="8px" disabled={isSubmitting}>
                  Login
                </Button>
              </FormControl>
            </Form>
          )}
        </Formik>
        <Link
          color="gray.500"
          href="https://ualki.com/terms-and-conditions"
          fontSize="sm"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
      </VStack>
    </PublicRoute>
  );
};
