import L, { LatLng } from "leaflet";
import { useMemo, useState } from "react";
import { Circle, Tooltip } from "react-leaflet";

interface DotMarkerProps {
  latlng: LatLng;
  type: "ROUTE_POINT" | "STOP" | "AUTO_PLAY" | "POINT_OF_INTEREST";
  focus: boolean;
  initialPoint?: boolean;
  lastPoint?: boolean;
  reference?: boolean;
  setInsertAfterThis: (i: number) => void;
  index: number;
  showLabels: boolean;
}

const RADIUS_ROUTE_POINT = 2;

export const RouteDotMarker = ({
  latlng,
  focus,
  initialPoint,
  lastPoint,
  reference,
  setInsertAfterThis,
  index,
  showLabels,
}: DotMarkerProps) => {
  const [radius, setRadius] = useState(RADIUS_ROUTE_POINT);
  const handlers = useMemo(
    () => ({
      mouseover() {
        setRadius(radius * 2);
      },
      mouseout() {
        setRadius(radius / 2);
      },
      click() {
        setInsertAfterThis(index);
      },
    }),
    [radius, index, setInsertAfterThis]
  );

  const circleColor = reference
    ? "#805AD5"
    : focus
    ? "#38A169"
    : initialPoint
    ? "#D69E2E"
    : lastPoint
    ? "#3182ce"
    : "#E53E3E";

  const circleCenter = L.polygon([latlng]).getBounds().getCenter();
  return (
    <Circle
      center={latlng}
      radius={focus || reference ? RADIUS_ROUTE_POINT * 2 : radius}
      pathOptions={{
        fillColor: circleColor,
        color: circleColor,
        fill: true,
        stroke: false,
        fillOpacity: 1,
      }}
      eventHandlers={handlers}
    >
      {showLabels && (
        <Tooltip
          position={
            new LatLng(
              circleCenter.lat,
              circleCenter.lng + circleCenter.lat * 0.000005
            )
          }
          permanent={true}
          direction="right"
          eventHandlers={handlers}
        >
          {String(index)}
        </Tooltip>
      )}
    </Circle>
  );
};
