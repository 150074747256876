import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Highlight,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
} from "@chakra-ui/react";
import { useField } from "formik";
import { isNil } from "lodash";
import { FaInfoCircle } from "react-icons/fa";

interface InputFieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  leftInputLabel?: string;
  description?: string;
  min?: string;
  max?: string;
  readOnly?: boolean;
}

export const InputField = ({
  name,
  label,
  type,
  leftInputLabel,
  required,
  description,
  ...props
}: InputFieldProps) => {
  const [field, meta] = useField(name);

  const drawHelper = () =>
    description && (
      <Box display="inline-block" marginRight="4px" position="relative">
        <Tooltip hasArrow label={description} bg="gray.300" color="black">
          <span>
            <FaInfoCircle
              style={{
                cursor: "pointer",
              }}
            />
          </span>
        </Tooltip>
      </Box>
    );

  const drawRequired = () =>
    required && (
      <Highlight
        query="(required)"
        styles={{ px: "1", py: "1", bg: "orange.100", fontSize: "10px" }}
      >
        (required)
      </Highlight>
    );

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <FormLabel htmlFor={field.name} style={{ position: "relative" }}>
        {drawHelper()}
        {`${label}`}
        {drawRequired()}
      </FormLabel>
      <InputGroup>
        {leftInputLabel && (
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="sm"
            children={leftInputLabel}
          />
        )}
        <Input
          id={name}
          {...field}
          value={!isNil(field.value) ? field.value : ""}
          type={type || "text"}
          {...props}
        />
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
