// import { LatLng, circle } from "leaflet";
import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";

interface PositionMarkerProps {
  onLocationFound: (latlng: LatLng) => void;
}

export const PositionMarker = ({ onLocationFound }: PositionMarkerProps) => {
  const [position, setPosition] = useState<LatLng | null>(null);
  const [bbox, setBbox] = useState<string[]>([]);

  const map = useMap();

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      onLocationFound(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
      // const radius = e.accuracy;
      // const circleRadius = circle(e.latlng, radius);
      // circleRadius.addTo(map);
      setBbox(e.bounds.toBBoxString().split(","));
    });
  }, [map, onLocationFound]);

  return position === null ? null : (
    <Marker position={position}>
      <Popup>
        You are here. <br />
        Map bbox: <br />
        <b>Southwest lng</b>: {bbox[0]} <br />
        <b>Southwest lat</b>: {bbox[1]} <br />
        <b>Northeast lng</b>: {bbox[2]} <br />
        <b>Northeast lat</b>: {bbox[3]}
      </Popup>
    </Marker>
  );
};
