import {
  Box,
  Heading,
  HStack,
  IconButton,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { FaGripLines, FaGripLinesVertical } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { useQuery } from "urql";
import { gqlReferenceQuery } from "../../api/queries/reference";
import { PrivateRoute } from "../../auth/routeMiddlewares";
import { MainMenu } from "../../components/mainMenu";
import { referenceData } from "../../state/reference";

interface BasePrivateProps {
  title: string;
  header?: ReactNode;
  children: ReactNode;
}
export const BasePrivate = ({ title, header, children }: BasePrivateProps) => {
  const bgHeader = useColorModeValue("gray.100", "blackAlpha.400");
  const [menuOpened, setMenuOpened] = useState(false);
  const [referenceDataValue, setReferenceData] = useRecoilState(referenceData);
  const [{ data, fetching: fetchingReference }] = useQuery({
    query: gqlReferenceQuery,
    pause: !!referenceDataValue.cities.length,
  });

  useEffect(() => {
    if (!fetchingReference && data) {
      setReferenceData(data);
    }
  }, [fetchingReference, data, setReferenceData]);

  return (
    <PrivateRoute>
      <HStack align="stretch" justify="flex-start" height="100vh">
        <MainMenu open={menuOpened} closeMenu={() => setMenuOpened(false)} />
        <VStack
          maxWidth="100%"
          flexGrow="1"
          margin={0}
          padding={0}
          style={{ WebkitMarginStart: 0 }}
          alignSelf="stretch"
          height="100vh"
          overflow="scroll"
        >
          <HStack
            bg={bgHeader}
            paddingBottom={5}
            paddingTop={5}
            paddingLeft={2}
            alignContent="center"
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignSelf="stretch"
          >
            <IconButton
              aria-label="Toggle Menu"
              size="lg"
              mr={2}
              display={{
                base: "block",
                md: "none",
              }}
              icon={menuOpened ? <FaGripLinesVertical /> : <FaGripLines />}
              onClick={() => setMenuOpened(!menuOpened)} // added line
            />
            {header || (
              <Heading as="h1" fontSize="l">
                {title}
              </Heading>
            )}
          </HStack>
          <Box maxWidth="100%" flexGrow="1" alignSelf="stretch">
            {children}
          </Box>
        </VStack>
      </HStack>
    </PrivateRoute>
  );
};
