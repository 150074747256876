import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
} from "@chakra-ui/react";
import { LatLng } from "leaflet";
import { ChangeEvent, useState } from "react";
import { FaPlus } from "react-icons/fa";

interface AddPointFromCoordinatesPopupProps {
  isOpen: boolean;
  onClose: (refresh?: boolean) => void;
  onAddRoutePointClick: (name?: string) => void;
  onAddStopPointClick: (name?: string) => void;
  onAddPoiClick: (name?: string) => void;
  setSelectedLocation: (latLng: LatLng | null) => void;
}

export const AddPointFromCoordinatesPopup = ({
  isOpen,
  onClose,
  onAddRoutePointClick,
  onAddStopPointClick,
  onAddPoiClick,
  setSelectedLocation,
}: AddPointFromCoordinatesPopupProps) => {
  const [type, setType] = useState("route");
  const [latText, setLatText] = useState("");
  const [nameText, setNameText] = useState("");
  const [lngText, setLngText] = useState("");

  const onChangeType = (evt: ChangeEvent<HTMLSelectElement>) => {
    setType(evt.target.value);
  };

  const onChangeLatText = (evt: ChangeEvent<HTMLInputElement>) => {
    setLatText(evt.target.value);

    if (evt.target.value.trim() === "" || lngText.trim() === "") {
      return;
    }

    setSelectedLocation(new LatLng(+evt.target.value, +lngText));
  };

  const onChangeName = (evt: ChangeEvent<HTMLInputElement>) => {
    setNameText(evt.target.value);
  };

  const onChangeLngText = (evt: ChangeEvent<HTMLInputElement>) => {
    setLngText(evt.target.value);

    if (latText.trim() === "" || evt.target.value.trim() === "") {
      return;
    }

    setSelectedLocation(new LatLng(+latText, +evt.target.value));
  };

  const onClickSave = () => {
    if (latText.trim() === "" || lngText.trim() === "") {
      return;
    }

    setSelectedLocation(new LatLng(+latText, +lngText));

    if (type === "route") {
      onAddRoutePointClick(nameText);
      onClose();
      return;
    }

    if (type === "stop") {
      onAddStopPointClick(nameText);
      onClose();
      return;
    }

    onAddPoiClick(nameText);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalContent>
        <ModalHeader>Add Point from Coordinates</ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="type" style={{ position: "relative" }}>
              Type of Point
            </FormLabel>
            <Select name="type" onChange={onChangeType} value={type}>
              <option value="poi">Point of Interest</option>
              <option value="route">Route Point</option>
              <option value="stop">Stop Point</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="lat" style={{ position: "relative" }}>
              Latitude
            </FormLabel>
            <Input name="lat" onChange={onChangeLatText} value={latText} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="lng" style={{ position: "relative" }}>
              Longitude
            </FormLabel>
            <Input name="lng" onChange={onChangeLngText} value={lngText} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="name" style={{ position: "relative" }}>
              Name
            </FormLabel>
            <Input name="name" onChange={onChangeName} value={nameText} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            disabled={!(latText + lngText).length}
            onClick={onClickSave}
          >
            <FaPlus /> <Text marginLeft="4px">Add</Text>
          </Button>
          <Button variant="secondary" ml={3} onClick={() => onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
