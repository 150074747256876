import { IconButton, VStack } from "@chakra-ui/react";
import { useState } from "react";
import ReactHowler from "react-howler";
import { FaPlay, FaStop } from "react-icons/fa";
import { MediaWrapper, MediaWrapperProps } from "./wrapper";

export const MediaAudio = ({
  name,
  src,
  loading,
  onClickRemove,
}: MediaWrapperProps) => {
  const [play, setPlay] = useState(false);
  const onStop = () => {
    setPlay(false);
  };
  return (
    <MediaWrapper
      name={name}
      src={src}
      loading={loading}
      onClickRemove={onClickRemove}
    >
      <VStack
        width="100%"
        height="100%"
        justifyContent="center"
        border="2px dotted #ccc"
        borderRadius={5}
      >
        <ReactHowler src={[src]} playing={play} onStop={onStop} />
        {!play && (
          <IconButton
            width="64px"
            height="64px"
            icon={<FaPlay />}
            onClick={() => setPlay(true)}
            aria-label="play"
            colorScheme="green"
          />
        )}
        {play && (
          <IconButton
            width="64px"
            height="64px"
            icon={<FaStop />}
            colorScheme="red"
            onClick={onStop}
            aria-label="stop"
          />
        )}
      </VStack>
    </MediaWrapper>
  );
};
