import axios from "axios";
import { tokenManagement } from "../auth/token";

type MEDIA_LOCATION =
  | "tour.screenshot"
  | "tour.image"
  | "tour.introductionVideo"
  | "tour.routePoint.audio"
  | "tour.routePoint.image"
  | "tour.routePoint.video"
  | "tour.pointOfInterest.audio"
  | "tour.pointOfInterest.image"
  | "tour.pointOfInterest.video";

interface MediaSettings {
  location: MEDIA_LOCATION;
  languageCode?: string;
  index?: number;
}

class RestAPI {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  async login(username: string, password: string) {
    const { data } = await axios.post(`${this.url}/login`, {
      username,
      password,
    });

    if (data.access_token) {
      return data.access_token;
    } else {
      return false;
    }
  }

  async uploadMedia(
    tourId: string,
    files: File[],
    mediaSettings: MediaSettings,
    callback: (file: File, name: string, succeed: boolean) => void
  ) {
    for (const file of files) {
      const formData = new FormData();

      formData.append("file", file);
      if (mediaSettings.languageCode) {
        formData.append("languageCode", mediaSettings.languageCode);
      }
      formData.append("location", mediaSettings.location);
      formData.append(
        "index",
        String(
          mediaSettings.index !== undefined && mediaSettings.index >= 0
            ? mediaSettings.index
            : -1
        )
      );

      try {
        const { data } = await axios.post(
          `${this.url}/media/${tourId}`,
          formData,
          {
            headers: {
              authorization: `Bearer ${tokenManagement.getAuthToken()}`,
            },
          }
        );

        callback(file, data.filename || "", data.error ? false : true);
      } catch {
        callback(file, "", false);
      }
    }
  }

  async uploadProfile(
    file: File,
    callback: (file: File, name: string, succeed: boolean) => void
  ) {
    const formData = new FormData();

    formData.append("file", file);

    try {
      const { data } = await axios.post(
        `${this.url}/media/user-profile`,
        formData,
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      callback(file, data.filename || "", data.error ? false : true);
    } catch {
      callback(file, "", false);
    }
  }

  async deleteProfilePhoto(
    file: string,
    callback: (file: string, succeed: boolean) => void
  ) {
    try {
      const { data } = await axios.delete(`${this.url}/media/user-profile`, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      callback(file, data.error ? false : true);
    } catch {
      callback(file, false);
    }
  }

  async getProfile() {
    try {
      const { data } = await axios.get(`${this.url}/media/user-profile`, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      return data;
    } catch {
      return {};
    }
  }

  async deleteMedia(
    tourId: string,
    filename: string,
    mediaSettings: MediaSettings,
    callback: (name: string, succeed: boolean) => void
  ) {
    try {
      const { data } = await axios.put(
        `${this.url}/media/${tourId}/delete`,
        {
          ...mediaSettings,
          index: String(
            mediaSettings.index !== undefined && mediaSettings.index >= 0
              ? mediaSettings.index
              : -1
          ),
          filename,
        },
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      callback(filename, data.error ? false : true);
    } catch {
      callback(filename, false);
    }
  }
}

export const restClient = new RestAPI(
  process.env.REACT_APP_SERVER_API || "http://localhost:8080"
);
