import { Button, HStack, Text, Box, VStack, Divider } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";
import { FaPlusSquare } from "react-icons/fa";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES, getMediaURL } from "./utils";
import Medias from "./medias";
import styles from "./mediaUpload.module.css";

interface MediaUploadProps {
  label: string;
  uploadFiles: (
    files: File[],
    cb: (file: File, filename: string, succeed: boolean) => void
  ) => void;
  onRemoveFile: (
    filename: string,
    cb: (filename: string, succeed: boolean) => void
  ) => void;
  files?: string[];
  multiple?: boolean;
  type?: "image" | "video" | "audio";
}

interface FilesToAdd {
  order: string[];
  [key: string]: File | string[];
}

const ACCEPTED_FORMATS = {
  image: ".jpg,.png,.jpeg",
  video: ".mp4",
  audio: ".acc,.ogg,.mp3,.wav",
};

export const MediaUpload = ({
  files = [],
  label,
  uploadFiles,
  onRemoveFile,
  multiple = false,
  type = "image",
}: MediaUploadProps) => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [filesToAdd, setFilesToAdd] = useState<FilesToAdd>({ order: [] });
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(files);
  const MediaComponent = Medias[type];

  const handleClickUploadFile = () => {
    fileInputField?.current?.click();
  };

  const fileUploadedCallback = (
    updatedFile: File,
    filename: string,
    succeed: boolean
  ) => {
    if (succeed) {
      const newFilesToAdd = structuredClone(filesToAdd);
      newFilesToAdd.order = filesToAdd.order.filter(
        (filename: string) => filename !== updatedFile.name
      );
      delete newFilesToAdd[updatedFile.name];
      setFilesToAdd(newFilesToAdd);
      setUploadedFiles([...structuredClone(uploadedFiles), filename]);
    }
  };

  const handleNewFileSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.target;
    if (newFiles?.length) {
      const newFilesToAdd: FilesToAdd = { order: [] };

      for (const file of newFiles) {
        if (file.size < DEFAULT_MAX_FILE_SIZE_IN_BYTES[type]) {
          newFilesToAdd[file.name] = file;
          newFilesToAdd.order.push(file.name);
          if (!multiple) {
            break;
          }
        }
      }

      if (multiple) {
        newFilesToAdd.order.splice(0, 0, ...filesToAdd.order);
        filesToAdd.order.forEach((filename: string) => {
          newFilesToAdd[filename] = filesToAdd[filename];
        });
      }

      setFilesToAdd(newFilesToAdd);
      if (newFilesToAdd.order.length) {
        uploadFiles(
          newFilesToAdd.order.map(
            (filename: string) => newFilesToAdd[filename] as File
          ),
          fileUploadedCallback
        );
      }
    }
  };

  const fileRemovedCallback = (filename: string, succeed: boolean) => {
    if (succeed) {
      setUploadedFiles(
        structuredClone(uploadedFiles).filter(
          (file: string) => file !== filename
        )
      );
    }
  };

  const onRemoveFilename = (filename: string) => () => {
    onRemoveFile(filename, fileRemovedCallback);
  };

  const isFull =
    !multiple && (uploadedFiles.length >= 1 || filesToAdd.order.length >= 1);

  return (
    <VStack
      width="100%"
      alignItems="flex-start"
      fontWeight="bold"
      marginTop={4}
    >
      <Divider />
      <Text textAlign="left" fontSize="md">
        {label}:
      </Text>
      <HStack wrap="wrap" justifyContent="flex-start" width="100%">
        {filesToAdd.order.map((filename) => {
          const file = filesToAdd[filename];
          return (
            <Box key={filename} maxWidth="300px">
              <MediaComponent
                name={filename}
                src={URL.createObjectURL(file as File)}
                loading={true}
              />
            </Box>
          );
        })}
        {(uploadedFiles || []).map((filename: string) => {
          return (
            <Box key={filename}>
              <MediaComponent
                name={filename}
                src={getMediaURL(filename)}
                loading={false}
                onClickRemove={onRemoveFilename(filename)}
              />
            </Box>
          );
        })}
        {!isFull && (
          <Box
            border="2px dotted lightgray"
            padding="35px 20px"
            borderRadius={5}
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="200px"
            height="200px"
          >
            <FaPlusSquare />
            <Text fontSize="sm" margin="8px 0">
              Drag and drop your files anywhere or
            </Text>
            <Button
              className={styles.uploadButton}
              onClick={handleClickUploadFile}
              marginTop={3}
            >
              <Text fontSize="sm" marginLeft={2}>
                {" "}
                Upload {multiple ? "files" : "a file"}
              </Text>
            </Button>
            <input
              type="file"
              ref={fileInputField}
              title=""
              value=""
              accept={ACCEPTED_FORMATS[type]}
              multiple={multiple}
              onChange={handleNewFileSelection}
              className={styles.inputFile}
            />
          </Box>
        )}
      </HStack>
    </VStack>
  );
};
