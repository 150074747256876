import {
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  Input,
  Text,
  Button,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { restAdminClient } from "../../../api/admin.api";
import { tokenManagement } from "../../../auth/token";
import { User, UserStatus } from "../../../entities/user";

import { BasePrivate } from "../../basePrivate";
import { StatusSelect } from "./components/statusSelect";

export const AdminUsers = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  if (!tokenManagement.isAdmin()) {
    navigate("/dashboard");
  }

  const fetchUsers = () => {
    restAdminClient
      .getUsers()
      .then((users) => {
        setUsers(users);
      })
      .catch(() => {
        setUsers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const onChangeStatus = (id: string) => (
    evt: ChangeEvent<HTMLSelectElement>
  ) => {
    restAdminClient.updateUserStatus(id, evt.currentTarget.value as UserStatus);
  };

  const onChangeNewUserName = (evt: ChangeEvent<HTMLInputElement>) => {
    setNewUserName(evt.currentTarget.value);
  };

  const onChangeNewUserEmail = (evt: ChangeEvent<HTMLInputElement>) => {
    setNewUserEmail(evt.currentTarget.value);
  };

  const onChangeNewUserPassword = (evt: ChangeEvent<HTMLInputElement>) => {
    setNewUserPassword(evt.currentTarget.value);
  };

  const addUser = () => {
    if (newUserName.length && newUserEmail.length && newUserPassword.length) {
      setError("");
      restAdminClient
        .addUser(newUserEmail, newUserName, newUserPassword)
        .then(() => {
          setNewUserName("");
          setNewUserEmail("");
          setNewUserPassword("");
          fetchUsers();
        })
        .catch((err) => {
          setError(
            err.response.data.message || "There was an error adding the user"
          );
        });
    }
  };

  return (
    <BasePrivate title="Users (Admin)">
      <VStack padding={3} alignItems="flex-start">
        <HStack>
          <Input
            onChange={onChangeNewUserName}
            value={newUserName}
            placeholder="User name"
          />
          <Input
            onChange={onChangeNewUserEmail}
            value={newUserEmail}
            placeholder="User email"
          />
          <Input
            onChange={onChangeNewUserPassword}
            value={newUserPassword}
            type="password"
            placeholder="User password"
          />
          <Button
            onClick={addUser}
            disabled={
              !newUserName.length ||
              !newUserEmail.length ||
              !newUserPassword.length
            }
          >
            Add
          </Button>
        </HStack>
        {error.length && (
          <Text color="red" fontSize="xs">
            {error}
          </Text>
        )}
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Created At</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading && <Spinner />}
              {!loading &&
                users.map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <StatusSelect
                        selected={user.status!}
                        onChange={onChangeStatus(user.id!)}
                      />
                    </Td>
                    <Td>{user.createdAt}</Td>
                  </Tr>
                ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Created At</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </VStack>
    </BasePrivate>
  );
};
