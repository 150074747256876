import { FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { ContentEditableEvent, DefaultEditor } from "react-simple-wysiwyg";
import { restClient } from "../../../../api/rest.api";
import { MediaUpload } from "../../../../components/mediaUpload";
import { TourDetail } from "../../../../entities/tour";
import "./styles.css";

interface LanguageDetailsProps {
  tourId: string;
  tourLanguage: TourDetail;
  setTourLanguage: (tourLanguage: TourDetail) => void;
}

export const LanguageDetails = ({
  tourId,
  tourLanguage,
  setTourLanguage,
}: LanguageDetailsProps) => {
  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setTourLanguage({ ...tourLanguage, name: event.target.value });
  };
  const onChangeDescription = (event: ContentEditableEvent) => {
    setTourLanguage({ ...tourLanguage, description: event.target.value });
  };

  const uploadImage = (
    files: File[],
    callback: (file: File, filename: string, succeed: boolean) => void
  ) => {
    restClient.uploadMedia(
      tourId,
      files,
      {
        location: "tour.image",
        languageCode: tourLanguage.languageCode,
      },
      callback
    );
  };

  const removeImage = (
    filename: string,
    callback: (filename: string, succeed: boolean) => void
  ) => {
    restClient.deleteMedia(
      tourId,
      filename,
      {
        location: "tour.image",
        languageCode: tourLanguage.languageCode,
      },
      callback
    );
  };

  const uploadVideo = (
    files: File[],
    callback: (file: File, filename: string, succeed: boolean) => void
  ) => {
    restClient.uploadMedia(
      tourId,
      files,
      {
        location: "tour.introductionVideo",
        languageCode: tourLanguage.languageCode,
      },
      callback
    );
  };

  const removeVideo = (
    filename: string,
    callback: (filename: string, succeed: boolean) => void
  ) => {
    restClient.deleteMedia(
      tourId,
      filename,
      {
        location: "tour.introductionVideo",
        languageCode: tourLanguage.languageCode,
      },
      callback
    );
  };

  return (
    <VStack>
      <FormControl>
        <FormLabel>Name:</FormLabel>
        <Input value={tourLanguage.name} onChange={onChangeName} />
      </FormControl>
      <FormControl textAlign="left">
        <FormLabel>Description:</FormLabel>
        <DefaultEditor
          value={tourLanguage.description}
          onChange={onChangeDescription}
        />
      </FormControl>
      <MediaUpload
        label="Images"
        multiple={true}
        files={tourLanguage.images || []}
        uploadFiles={uploadImage}
        onRemoveFile={removeImage}
      />
      <MediaUpload
        label="Video Presentation"
        multiple={false}
        files={
          tourLanguage.introductionVideo ? [tourLanguage.introductionVideo] : []
        }
        uploadFiles={uploadVideo}
        onRemoveFile={removeVideo}
        type="video"
      />
    </VStack>
  );
};
