import * as yup from "yup";

const tourDetails = yup.object().shape({
  name: yup.string().required("Please complete the language"),
  description: yup.string(),
});

export const tourSchema = yup.object().shape({
  name: yup.string().required("Please enter a name"),
  tourDuration: yup.string(),
  tourDetails: yup.array().of(tourDetails),
  tags: yup.string(),
  languagesCode: yup.string(),
});
