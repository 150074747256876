import { ChangeEvent, KeyboardEvent, useState } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Highlight,
  HStack,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import { FaInfoCircle } from "react-icons/fa";

interface TagsFieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  description?: string;
  min?: string;
  max?: string;
  stringSeparator?: string;
  suggestion?: string[];
}

export const TagsField = ({
  name,
  label,
  type,
  required,
  description,
  stringSeparator = ";",
  suggestion,
  ...props
}: TagsFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const [newTag, setNewTag] = useState("");

  const drawHelper = () =>
    description && (
      <Box display="inline-block" marginRight="4px" position="relative">
        <Tooltip hasArrow label={description} bg="gray.300" color="black">
          <span>
            <FaInfoCircle
              style={{
                cursor: "pointer",
              }}
            />
          </span>
        </Tooltip>
      </Box>
    );

  const drawRequired = () =>
    required && (
      <Highlight
        query="(required)"
        styles={{ px: "1", py: "1", bg: "orange.100", fontSize: "10px" }}
      >
        (required)
      </Highlight>
    );

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      let newTags = "";
      if (field.value.length) {
        newTags = field.value + stringSeparator;
      }

      newTags += newTag;
      setNewTag("");
      helpers.setValue(newTags);
    }
  };

  const addDefaultTag = (tag: string) => () => {
    let newTags = "";
    if (field.value.length) {
      newTags = field.value + stringSeparator;
    }

    newTags += tag;
    helpers.setValue(newTags);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewTag(event.target.value);
  };

  const removeTag = (removeTag: string) => () => {
    const newTags = (field.value.split(stringSeparator) || []).filter(
      (tag: string) => tag !== removeTag
    );
    helpers.setValue(newTags.join(stringSeparator));
  };

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <FormLabel htmlFor={field.name} style={{ position: "relative" }}>
        {drawHelper()}
        {`${label}`}
        {drawRequired()}
      </FormLabel>
      <Input
        id={name}
        {...field}
        type={type || "text"}
        {...props}
        display="none"
      />
      <HStack spacing={4} paddingTop={2} paddingBottom={2} wrap="wrap">
        {field.value.length &&
          (field.value.split(stringSeparator) || []).map((tag: string) => (
            <Tag
              size="sm"
              key={tag}
              borderRadius="full"
              variant="solid"
              colorScheme="green"
              paddingLeft={2}
              paddingRight={2}
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={removeTag(tag)} />
            </Tag>
          ))}
      </HStack>
      <Input
        type={type || "text"}
        onKeyDown={onKeyDown}
        value={newTag}
        onChange={onChange}
      />
      {suggestion && (
        <HStack spacing={1} paddingTop={2} paddingBottom={2} wrap="wrap">
          <Text fontSize="sm">Default values:</Text>
          {(suggestion || []).map((s: string) => (
            <Tag
              cursor="pointer"
              size="sm"
              key={s}
              padding={1}
              borderRadius="full"
              variant="solid"
              colorScheme="gray"
              onClick={addDefaultTag(s)}
            >
              <TagLabel>{s}</TagLabel>
            </Tag>
          ))}
        </HStack>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
