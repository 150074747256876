import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FaHome, FaSignOutAlt, FaUserShield } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { tokenManagement } from "../../auth/token";
import { ColorModeSwitcher } from "../colorModeSwitcher";
import { AdminMenu } from "./adminMenu";
import { TourMenu } from "./tourMenu";

interface MainMenuProps {
  open: boolean;
  closeMenu: () => void;
}

export const MainMenu = ({ open, closeMenu }: MainMenuProps) => {
  const { tourId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const btnRef = useRef(null);
  const bgMenu = useColorModeValue("gray.300", "blackAlpha.500");
  const [showAdmin, setShowAdmin] = useState<boolean>(
    location.pathname.indexOf("/admin") > -1
  );

  const signOut = () => {
    tokenManagement.removeAuthToken();
    window.location.href = "/";
  };

  return (
    <VStack
      width="80px"
      bg={bgMenu}
      display={{
        base: open ? "block" : "none",
        md: "block",
      }}
      justifyContent="space-between"
      alignContent="top"
      paddingTop="8px"
    >
      <VStack>
        <Tag size="sm">MVP</Tag>
        <IconButton
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          _hover={{
            background: "transparent",
            opacity: 0.7,
          }}
          onClick={() => {
            closeMenu();
            navigate("/profile");
          }}
          icon={<Avatar name="Benjamin Simon" src="" boxSize="40px" />}
          aria-label={`Show/Hide Menu`}
        />
      </VStack>
      <VStack>
        <Divider />
        {tourId && !showAdmin && <TourMenu closeMenu={closeMenu} />}
        {showAdmin && <AdminMenu closeMenu={() => setShowAdmin(false)} />}

        {!tourId && !showAdmin && (
          <>
            <Button
              size="md"
              fontSize="lg"
              variant="ghost"
              color="current"
              ref={btnRef}
              colorScheme="teal"
              onClick={() => {
                closeMenu();
                navigate("/dashboard");
              }}
              aria-label={`Home`}
            >
              <VStack>
                <FaHome />
                <Text fontSize="xs">Home</Text>
              </VStack>
            </Button>
            {tokenManagement.isAdmin() && (
              <Button
                size="md"
                fontSize="lg"
                variant="ghost"
                color="current"
                ref={btnRef}
                colorScheme="teal"
                onClick={() => {
                  setShowAdmin(true);
                }}
                aria-label={`Admin`}
              >
                <VStack>
                  <FaUserShield />
                  <Text fontSize="xs">Admin</Text>
                </VStack>
              </Button>
            )}
          </>
        )}
        <Divider />
      </VStack>

      <VStack>
        <Divider />
        <Button
          onClick={signOut}
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          aria-label={`Logout`}
        >
          <VStack>
            <FaSignOutAlt />
            <Text fontSize="xs">Logout</Text>
          </VStack>
        </Button>
        <Divider />
        <Tooltip label="Change color mode" aria-label="Change color mode">
          <ColorModeSwitcher />
        </Tooltip>
      </VStack>
    </VStack>
  );
};
