import { ColorModeScript } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { Provider } from "urql";
import { RecoilRoot } from "recoil";
import { gqlClient } from "./api/graphql.api";
import { AuthContext } from "./auth/context";
import { ChakraProvider, Box, Grid, theme } from "@chakra-ui/react";
import { Dashboard } from "./pages/dashboard";
import { Error404Page } from "./pages/error404";
import { HomePage } from "./pages/home";
import { tokenManagement } from "./auth/token";
import { Tour } from "./pages/tour";
import { Builder } from "./pages/tour/builder";
import { Details } from "./pages/tour/details";
import { Profile } from "./pages/profile";
import { AdminUsers } from "./pages/admin/users";
import { AdminTours } from "./pages/admin/tours";
import { AdminImportTours } from "./pages/admin/import";
import { AdminCities } from "./pages/admin/cities";
import { AdminCountries } from "./pages/admin/countries";

export const App = () => {
  return (
    <RecoilRoot>
      <AuthContext.Provider value={tokenManagement.getAuthContext()}>
        <Provider value={gqlClient}>
          <ColorModeScript />
          <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
              <Grid minH="100vh">
                <Routes>
                  <Route index element={<HomePage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/tour/:tourId" element={<Tour />} />
                  <Route path="/tour/:tourId/details" element={<Details />} />
                  <Route path="/tour/:tourId/builder" element={<Builder />} />
                  <Route path="/admin/users" element={<AdminUsers />} />
                  <Route path="/admin/countries" element={<AdminCountries />} />
                  <Route path="/admin/cities" element={<AdminCities />} />
                  <Route path="/admin/tours" element={<AdminTours />} />
                  <Route
                    path="/admin/importer"
                    element={<AdminImportTours />}
                  />
                  <Route path="*" element={<Error404Page />} />
                </Routes>
              </Grid>
            </Box>
          </ChakraProvider>
        </Provider>
      </AuthContext.Provider>
    </RecoilRoot>
  );
};
