import {
  Box,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { restAdminClient } from "../../../../api/admin.api";
import { MediaVideo } from "../../../../components/mediaUpload/medias/video";
import { getMediaURL } from "../../../../components/mediaUpload/utils";
import { Tour } from "../../../../entities/tour";
import { RoutePointsDetails } from "./routePointsDetails";

interface TourPopupProps {
  tourId: string | null;
  onClose: () => void;
}

export const TourPopup = ({ tourId, onClose }: TourPopupProps) => {
  const [tour, setTour] = useState<Tour | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (tourId) {
      restAdminClient
        .getTourById(tourId)
        .then((res) => {
          setTour(res);
        })
        .catch(() => {
          setTour(null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setTour(null);
    }
  }, [tourId]);

  return (
    <Modal
      isOpen={!!tourId}
      onClose={onClose}
      blockScrollOnMount={true}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          View Tour: {loading || !tour ? "???" : tour.name}{" "}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && <Spinner />}
          {!loading && tour && (
            <>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Country & City
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.city?.country?.name} - {tour.city?.name}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Author
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.author?.email}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Languages
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.languagesCode?.join(",") || "-"}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Default Language
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.defaultLanguageCode || "-"}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Price
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.price
                      ? `${tour.price.currency} ${tour.price.price}`
                      : "-"}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Tour Duration
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.tourDuration || "-"}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Tour Distance
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.tourDistance || "-"}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Tags
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.tags?.join(",")}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Created At / Updated At
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {tour.createdAt} - {tour.updatedAt}
                  </Text>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Tour Details
                  </Heading>
                  <Tabs isFitted variant="enclosed">
                    <TabList>
                      {(tour.tourDetails || []).map((tourDetail) => (
                        <Tab key={tourDetail.languageCode}>
                          {tourDetail.languageCode}
                        </Tab>
                      ))}
                    </TabList>

                    <TabPanels>
                      {(tour.tourDetails || []).map((tourDetail) => (
                        <TabPanel key={tourDetail.languageCode}>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Language: {tourDetail.languageCode}
                            </Heading>
                          </Box>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Description
                            </Heading>
                            <Text pt="2" fontSize="sm">
                              {tourDetail.description}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Images
                            </Heading>
                            <Text pt="2" fontSize="sm">
                              {tourDetail.images?.map((image: string) => (
                                <Image
                                  alt={image}
                                  src={getMediaURL(image)}
                                  key={image}
                                  width="300"
                                  height="300"
                                />
                              ))}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Introduction Video
                            </Heading>
                            {(tourDetail.introductionVideo && (
                              <MediaVideo
                                name="Introduction Video"
                                src={getMediaURL(tourDetail.introductionVideo)}
                              />
                            )) ||
                              "Not Provided"}
                          </Box>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Route Points
                  </Heading>

                  {tour.routePoints && (
                    <RoutePointsDetails points={tour.routePoints} />
                  )}
                  {!tour.routePoints && "Route not provided"}
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Points of Interest
                  </Heading>

                  {tour.pointsOfInterest && (
                    <RoutePointsDetails points={tour.pointsOfInterest} />
                  )}
                  {!tour.pointsOfInterest && "Points of interest not provided"}
                </Box>
              </Stack>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
