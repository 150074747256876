import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Highlight,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { useField } from "formik";
import { FaInfoCircle } from "react-icons/fa";

interface SelectFieldProps {
  name: string;
  label: string;
  options: { id: string; name: string }[];
  required?: boolean;
  description?: string;
  defaultValue?: string;
}

export const SelectField = ({
  name,
  label,
  options,
  required,
  description,
  defaultValue,
}: SelectFieldProps) => {
  const [field, meta] = useField(name);

  const drawHelper = () =>
    description && (
      <Box display="inline-block" marginRight="4px" position="relative">
        <Tooltip hasArrow label={description} bg="gray.300" color="black">
          <span>
            <FaInfoCircle
              style={{
                cursor: "pointer",
              }}
            />
          </span>
        </Tooltip>
      </Box>
    );

  const drawRequired = () =>
    required && (
      <Highlight
        query="(required)"
        styles={{ px: "1", py: "1", bg: "orange.100", fontSize: "10px" }}
      >
        (required)
      </Highlight>
    );

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <FormLabel htmlFor={field.name} style={{ position: "relative" }}>
        {drawHelper()}
        {`${label}`}
        {drawRequired()}
      </FormLabel>
      <Select id={name} {...field} value={field.value || defaultValue}>
        {options.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
