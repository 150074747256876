import { Tooltip, IconButton, HStack, VStack, Switch } from "@chakra-ui/react";
import { LatLng } from "leaflet";
import { FaLocationArrow } from "react-icons/fa";
import { useMap } from "react-leaflet";

interface ToolsMenuProp {
  enabledLocation: boolean;
  currentLocation: LatLng | null;
  setEnabledLocation: (enabledLocation: boolean) => void;
}

export const ToolsMenu = ({
  enabledLocation,
  currentLocation,
  setEnabledLocation,
}: ToolsMenuProp) => {
  const toogleLocation = () => setEnabledLocation(!enabledLocation);
  const map = useMap();
  const goToCurrentPosition = () => {
    currentLocation && map.flyTo(currentLocation, map.getZoom());
  };
  return (
    <VStack
      position="absolute"
      right="15px"
      bottom="25px"
      zIndex={999}
      justifyContent="space-between"
      alignContent="center"
      alignSelf="center"
      borderTopRadius={5}
    >
      <Tooltip label="Track your position" placement="top">
        <HStack
          background="#FFFB"
          paddingTop="4px"
          paddingBottom="4px"
          paddingLeft="8px"
          paddingRight="8px"
        >
          <Switch
            id="enableLocation"
            isChecked={enabledLocation}
            onChange={toogleLocation}
          />
          <IconButton
            size="sm"
            fontSize="lg"
            variant="ghost"
            color="current"
            colorScheme="teal"
            onClick={goToCurrentPosition}
            icon={<FaLocationArrow />}
            aria-label={`Route`}
          />
        </HStack>
      </Tooltip>
    </VStack>
  );
};
