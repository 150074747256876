import {
  Button,
  HStack,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { restAdminClient } from "../../../api/admin.api";
import { tokenManagement } from "../../../auth/token";
import { City, Country } from "../../../entities/reference";

import { BasePrivate } from "../../basePrivate";

export const AdminCities = () => {
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState<City[]>([]);
  const [cityName, setCityName] = useState("");
  const [cityLat, setCityLat] = useState("");
  const [cityLng, setCityLng] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");
  const [countries, setCountries] = useState<Country[]>([]);

  const navigate = useNavigate();

  if (!tokenManagement.isAdmin()) {
    navigate("/dashboard");
  }

  const fetchCountries = () =>
    restAdminClient
      .getCountries()
      .then((countries) => {
        setCountries(countries);
      })
      .catch(() => {
        setCountries([]);
      })
      .finally(() => {
        setLoading(false);
      });

  const fetchCities = () => {
    restAdminClient
      .getCities()
      .then((cities) => {
        setCities(cities);
      })
      .catch(() => {
        setCities([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCountries();
    fetchCities();
  }, []);

  const onChangeCityLat = (evt: ChangeEvent<HTMLInputElement>) => {
    setCityLat(evt.currentTarget.value);
  };

  const onChangeCityLng = (evt: ChangeEvent<HTMLInputElement>) => {
    setCityLng(evt.currentTarget.value);
  };

  const onChangeCityName = (evt: ChangeEvent<HTMLInputElement>) => {
    setCityName(evt.currentTarget.value);
  };

  const onChangeCountry = (evt: ChangeEvent<HTMLSelectElement>) => {
    setCountry(evt.currentTarget.value);
  };

  const addCountry = () => {
    if (cityName.length && cityLat.length && cityLng.length && country.length) {
      setError("");
      restAdminClient
        .addCity(cityName, country, cityLat, cityLng)
        .then(() => {
          setCountry("");
          setCityName("");
          fetchCities();
        })
        .catch((err) => {
          setError(
            err.response.data.message || "There was an error adding the city"
          );
        });
    }
  };

  return (
    <BasePrivate title="Cities (Admin)">
      <VStack padding={3} alignItems="flex-start">
        <HStack>
          <Select
            onChange={onChangeCountry}
            value={country}
            placeholder="Select country"
          >
            {countries.map((country) => (
              <option value={country.id} key={country.id}>
                {country.name}
              </option>
            ))}
          </Select>
          <Input
            onChange={onChangeCityLat}
            value={cityLat}
            placeholder="Latitude"
          />
          <Input
            onChange={onChangeCityLng}
            value={cityLng}
            placeholder="Longitude"
          />
          <Input
            onChange={onChangeCityName}
            value={cityName}
            placeholder="Name"
          />
          <Button
            onClick={addCountry}
            disabled={
              !cityName.length ||
              !cityLat.length ||
              !cityLng.length ||
              !country.length
            }
          >
            Add
          </Button>
        </HStack>
        {error.length && (
          <Text color="red" size="xs">
            {error}
          </Text>
        )}
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Country</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading && <Spinner />}
              {!loading &&
                cities.map((city) => (
                  <Tr key={city.id}>
                    <Td>{city.id}</Td>
                    <Td>{city.name}</Td>
                    <Td>{city.country?.name}</Td>
                  </Tr>
                ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Country</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </VStack>
    </BasePrivate>
  );
};
