import { LatLng } from "leaflet";
const EARTH_RADIUS = 6371;

const toRadian = (degree: number): number => {
  return (degree * Math.PI) / 180;
};

export const calculate2PointsDistance = (
  origin: LatLng,
  destination: LatLng
) => {
  const originLng = toRadian(origin.lng);
  const originLat = toRadian(origin.lat);
  const destLng = toRadian(destination.lng);
  const destLat = toRadian(destination.lat);

  const deltaLat = destLat - originLat;
  const deltaLng = destLng - originLng;

  const a =
    Math.pow(Math.sin(deltaLat / 2), 2) +
    Math.cos(originLat) *
      Math.cos(destLat) *
      Math.pow(Math.sin(deltaLng / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));

  return Math.ceil(c * EARTH_RADIUS * 1000);
};

/**
 * Avg walking speed is between 3 and 6km/h, depending on the inclination of the map
 * 4.5 is a good number to use.
 */
