import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaArrowRight, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useMutation, useQuery } from "urql";
import { gqlTourMutationRemove } from "../../api/mutations/tour";
import { gqlToursQuery } from "../../api/queries/tour";
import { AddTourPopup } from "../../components/addTourPopup";
import { Tour } from "../../entities/tour";
import { mainMenu, OptionsName } from "../../state/menu";
import { BasePrivate } from "../basePrivate";

export const Dashboard = () => {
  const [mainMenuValue, setMainMenuValue] = useRecoilState(mainMenu);
  const navigate = useNavigate();

  const [{ data, fetching: fetchingQuery, error }, executeQuery] = useQuery({
    query: gqlToursQuery,
  });

  const [{ fetching: fetchingMutation }, executeMutationRemoveTour] =
    useMutation(gqlTourMutationRemove);

  const onAddTeamClick = () => {
    setMainMenuValue({
      ...mainMenuValue,
      addTour: true,
    });
  };

  const onClose = (option: OptionsName) => () => {
    setMainMenuValue({
      ...mainMenuValue,
      [option]: false,
    });
  };

  const removeItemAction = (removeTourId: string) => {
    executeMutationRemoveTour({
      removeTourId,
    }).then(() => {
      executeQuery({ requestPolicy: "network-only" });
    });
  };

  const selectItemAction = (params: Tour) => {
    setMainMenuValue({
      ...mainMenuValue,
      tours: false,
    });
    navigate(`/tour/${params.id}`);
  };

  return (
    <BasePrivate title="Dashboard">
      {fetchingQuery && !data && <Spinner />}

      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>There was an error!</AlertTitle>
          <AlertDescription>
            While retrieving the data from our servers we got an error. We
            reported it and we are currently working to fix it.
          </AlertDescription>
        </Alert>
      )}

      {!error && (!fetchingQuery || data) && (
        <>
          {mainMenuValue.addTour && (
            <AddTourPopup
              isOpen={mainMenuValue.addTour}
              onClose={onClose("addTour")}
            />
          )}
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(5, 1fr)",
            }}
            gap={6}
            padding={2}
          >
            {(data.tours || []).map((tour: Tour) => {
              const { id, name, city } = tour;
              return (
                <GridItem
                  maxWidth={{
                    base: "100%",
                    md: "300px",
                  }}
                  width="100%"
                  h="10"
                  bg="gray.100"
                  key={id}
                  height="150px"
                  padding={4}
                >
                  <VStack
                    alignItems="stretch"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <VStack alignItems="stretch">
                      <HStack justifyContent="space-between">
                        <Text textAlign="left" fontSize="md">
                          {name}
                        </Text>
                        <IconButton
                          colorScheme="red"
                          aria-label="Delete"
                          disabled={fetchingMutation}
                          size="xs"
                          onClick={() => removeItemAction(tour.id)}
                          icon={<FaTrashAlt />}
                        />
                      </HStack>
                      <Text textAlign="left" fontSize="xs">
                        {city.name}
                      </Text>
                    </VStack>
                    <HStack alignSelf="flex-end" justifySelf="flex-end">
                      <Button
                        marginLeft="4px"
                        colorScheme="green"
                        size="sm"
                        onClick={() => selectItemAction(tour)}
                      >
                        Select <FaArrowRight />
                      </Button>
                    </HStack>
                  </VStack>
                </GridItem>
              );
            })}
            <GridItem
              maxWidth={{
                base: "100%",
                md: "300px",
              }}
              width="100%"
              h="10"
              bg="gray.100"
              height="150px"
            >
              <Button height="100%" width="100%" onClick={onAddTeamClick}>
                <FaPlus /> <Text marginLeft="4px">Create</Text>
              </Button>
            </GridItem>
          </Grid>
        </>
      )}
    </BasePrivate>
  );
};
