import { SelectField } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { TourStatus, TourStatusValues } from "../../../../entities/tour";

interface StatusSelectProps {
  selected: TourStatus;
  onChange: (evt: ChangeEvent<HTMLSelectElement>) => void;
}
export const StatusSelect = ({ selected, onChange }: StatusSelectProps) => {
  return (
    <SelectField
      placeholder="Select the status"
      onChange={onChange}
      defaultValue={selected || TourStatusValues.DRAFT}
    >
      {Object.keys(TourStatusValues).map((status: string) => {
        return (
          <option value={status} key={status}>
            {TourStatusValues[status as TourStatus]}
          </option>
        );
      })}
    </SelectField>
  );
};
