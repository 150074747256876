import { LatLng } from "leaflet";
import { ReactNode } from "react";
import { PointDetails } from "../../../../../entities/pointDetails";
import { DotMarker } from "./dotMarker";

interface StopDrawerProps {
  dataPoints: PointDetails[];
  movingRoutePoint: number | null;
  setInsertAfterThis: (x: number) => void;
  insertAfterThis: number | null;
  showLabels: boolean;
}

export const StopDrawer = ({
  dataPoints,
  movingRoutePoint,
  insertAfterThis,
  setInsertAfterThis,
  showLabels,
}: StopDrawerProps) => {
  const routeComponents: { points: LatLng[]; circles: ReactNode[] } = dataPoints
    .filter((dataPoint: PointDetails) => dataPoint.location?.lat)
    .reduce(
      (acc: any, dataPoint: PointDetails, index: number) => {
        const newLatLng = new LatLng(
          parseFloat(dataPoint.location!.lat),
          parseFloat(dataPoint.location!.lng)
        );

        acc.points.push(newLatLng);
        acc.circles.push(
          <DotMarker
            key={dataPoint.location!.lat + dataPoint.location!.lng}
            type={dataPoint.type}
            focus={movingRoutePoint === index}
            reference={insertAfterThis === index}
            latlng={newLatLng}
            initialPoint={index === 0}
            lastPoint={index === dataPoints.length - 1}
            index={index}
            setInsertAfterThis={setInsertAfterThis}
            showLabels={showLabels}
          />
        );
        return acc;
      },
      { points: [], circles: [] }
    );

  return <>{routeComponents.circles}</>;
};
