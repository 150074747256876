import { Button, Divider, Spinner, Text, VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "urql";
import { gqlUserMutationUpdate } from "../../api/mutations/user";
import { restClient } from "../../api/rest.api";
import { tokenManagement } from "../../auth/token";
import { InputField } from "../../components/forms/inputField";
import { SwitchField } from "../../components/forms/switchField";
import { MediaUpload } from "../../components/mediaUpload";
import { User } from "../../entities/user";
import { userSchema } from "../../schemas/user";
import { BasePrivate } from "../basePrivate";

export const Profile = () => {
  const tokenData = tokenManagement.getTokenContent();
  const [error, setError] = useState(false);
  const [passwordDontMatchError, setPasswordDontMatchError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [fullUser, setFullUser] = useState<User>({
    email: tokenData.email,
    name: tokenData.name,
  });
  const [{ fetching: executingUpdate }, executeMutationUpdateUser] =
    useMutation(gqlUserMutationUpdate);

  useEffect(() => {
    restClient.getProfile().then((data: User) => {
      setFullUser(data);
      setFetchingUserData(false);
    });
  }, [setFullUser]);
  const uploadProfilePhoto = (
    file: File[],
    callback: (file: File, filename: string, succeed: boolean) => void
  ) => {
    restClient.uploadProfile(file[0], callback);
  };

  const deleteProfilePhoto = (
    file: string,
    callback: (file: string, succeed: boolean) => void
  ) => {
    restClient.deleteProfilePhoto(file, callback);
  };

  return (
    <BasePrivate title="Profile">
      {fetchingUserData ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={{
            email: tokenData.email,
            password: "",
            password2: "",
            name: tokenData.name,
            shareEmail: fullUser.shareEmail || false,
            description: fullUser.description || "",
            phoneNumber: fullUser.phoneNumber || "",
            sharePhoneNumber: fullUser.sharePhoneNumber || false,
            whatsapp: fullUser.whatsapp || false,
            instagram: fullUser.instagram || "",
            facebook: fullUser.facebook || "",
            twitter: fullUser.twitter || "",
            tripAdvisor: fullUser.tripAdvisor || "",
            website: fullUser.website || "",
            languages: fullUser.languages || "",
            photo: fullUser.photo || "",
          }}
          onSubmit={({
            name,
            password,
            password2,
            shareEmail,
            description,
            phoneNumber,
            sharePhoneNumber,
            whatsapp,
            instagram,
            facebook,
            twitter,
            tripAdvisor,
            website,
            languages,
          }) => {
            setError(false);
            setPasswordDontMatchError(false);

            if (password.length && password !== password2) {
              setPasswordDontMatchError(true);
              return;
            }

            const passwordObject = password.length
              ? {
                  password,
                }
              : {};

            executeMutationUpdateUser({
              updateUserInput: {
                name,
                email: tokenData.email,
                shareEmail,
                description,
                phoneNumber,
                sharePhoneNumber,
                whatsapp,
                instagram,
                facebook,
                twitter,
                tripAdvisor,
                website,
                languages,
                ...passwordObject,
              },
            })
              .then(() => {
                setUpdated(true);
              })
              .catch(() => {
                setError(true);
              });
          }}
          validationSchema={userSchema}
        >
          {() => (
            <Form>
              <VStack padding={3} alignItems="flex-start">
                {error && (
                  <Text textColor="#f00">
                    There was an error saving your data. Try again later.
                  </Text>
                )}
                {updated && (
                  <Text textColor="#00f">
                    Your changes have been save. Log out and log in again to
                    refresh your session.
                  </Text>
                )}
                <InputField
                  name="email"
                  readOnly={true}
                  label="Email"
                  description="You can not change your email"
                />
                <SwitchField name="shareEmail" label="Share Email" />
                <Divider marginTop={4} marginBottom={4} />
                <InputField name="name" label="Name" required={true} />
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="phoneNumber"
                  label="Phone Number"
                  description=""
                />
                <SwitchField
                  name="sharePhoneNumber"
                  label="Share your phone number"
                />
                <Divider marginTop={4} marginBottom={4} />
                <SwitchField name="whatsapp" label="Do you have whatsapp?" />
                <Divider marginTop={4} marginBottom={4} />
                <InputField name="facebook" label="Facebook" description="" />
                <Divider marginTop={4} marginBottom={4} />
                <InputField name="twitter" label="Twitter" description="" />
                <Divider marginTop={4} marginBottom={4} />
                <InputField name="instagram" label="Instagram" description="" />
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="tripAdvisor"
                  label="TripAdvisor"
                  description=""
                />
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="website"
                  label="Your Website"
                  description=""
                />
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="languages"
                  label="Languages you speak"
                  description="Help your audience to know how many languages you speak"
                />
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="description"
                  label="Description"
                  description="Describe yourself. Don't be shy! People wants to know more about you"
                />
                <Divider marginTop={4} marginBottom={4} />
                <MediaUpload
                  label="Profile Photo"
                  multiple={false}
                  files={fullUser.photo ? [fullUser.photo] : []}
                  uploadFiles={uploadProfilePhoto}
                  onRemoveFile={deleteProfilePhoto}
                  type="image"
                />
                <Divider marginTop={4} marginBottom={4} />
                <InputField
                  name="password"
                  label="Enter your new password"
                  type="password"
                  description="Changing your password is optional. Leave empty if you don't want to change."
                />
                <InputField
                  name="password2"
                  label="Repeat your password"
                  type="password"
                />
                {passwordDontMatchError && (
                  <Text textColor="#f00">Your passwords don't match</Text>
                )}
              </VStack>
              <Button
                position="absolute"
                bottom="15px"
                right="15px"
                disabled={executingUpdate}
                type="submit"
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </BasePrivate>
  );
};
