import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FaPlus } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import { useMutation } from "urql";
import { gqlTourMutationAdd } from "../../api/mutations/tour";
import { tourSchema } from "../../schemas/tour";
import { referenceData } from "../../state/reference";
import { InputField } from "../forms/inputField";
import { SelectField } from "../forms/selectField";

interface AddTourPopupProps {
  isOpen: boolean;
  onClose: (refresh?: boolean) => void;
}

export const AddTourPopup = ({ isOpen, onClose }: AddTourPopupProps) => {
  const [resultAddTour, executeMutationAddTour] =
    useMutation(gqlTourMutationAdd);
  const { cities } = useRecoilValue(referenceData);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalContent>
        <ModalHeader>Create Tour</ModalHeader>
        <ModalCloseButton />
        {!cities.length && (
          <ModalBody>
            <Spinner />
          </ModalBody>
        )}
        {cities.length && (
          <Formik
            initialValues={{
              name: "",
              cityId: cities[0].id,
            }}
            onSubmit={({ name, cityId }, { setSubmitting, resetForm }) => {
              executeMutationAddTour({
                createTourInput: {
                  name,
                  cityId,
                },
              }).then(() => {
                setSubmitting(false);
                resetForm();
                onClose(true);
              });
            }}
            validationSchema={tourSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <ModalBody>
                  <InputField name="name" label="Name" required={true} />
                  <SelectField name="cityId" label="City" options={cities} defaultValue={cities[0].id} />
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    disabled={isSubmitting || resultAddTour.fetching}
                    type="submit"
                  >
                    <FaPlus /> <Text marginLeft="4px">Save</Text>
                  </Button>
                  <Button variant="secondary" ml={3} onClick={() => onClose()}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </ModalContent>
    </Modal>
  );
};
