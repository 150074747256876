import { atom } from "recoil";

export type OptionsName = "tours" | "addTour";

export interface IMenuOptions {
  tours: boolean;
  addTour: boolean;
}

export const mainMenu = atom<IMenuOptions>({
  key: "mainMenu",
  default: {
    tours: false,
    addTour: false,
  },
});
