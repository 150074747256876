import {
  Checkbox,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ListItem,
} from "@chakra-ui/react";
import { DomUtil, Map } from "leaflet";
import { DraggableProvided } from "react-beautiful-dnd";
import { FaEdit, FaMapPin, FaTrash } from "react-icons/fa";
import { PointDetails } from "../../../../../entities/pointDetails";

interface DataPointMapProps {
  item?: PointDetails;
  onRemove: () => void;
  onEdit: () => void;
  onEditPointLocation: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  map: Map;
  provided: DraggableProvided;
  onClickInsertAfterThis: () => void;
  insertAfterThis: number | null;
  index: number;
}
export const DataPointMap = ({
  item,
  onRemove,
  onEdit,
  onEditPointLocation,
  onMouseOver,
  onMouseLeave,
  map,
  insertAfterThis,
  index,
  onClickInsertAfterThis,
  provided,
}: DataPointMapProps) => {
  const onFocusInput = () => {
    DomUtil.addClass(map.getContainer(), "crosshair-cursor-enabled");
    onEditPointLocation();
  };

  return (
    <ListItem
      ref={provided.innerRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <InputGroup size="md" background="white">
        <InputLeftElement
          children={
            <>
              <FaMapPin color="gray.300" />
              <Checkbox
                isChecked={insertAfterThis === index}
                onChange={onClickInsertAfterThis}
              />
            </>
          }
          cursor="grab"
        />
        <Input
          pr="4.5rem"
          border={0}
          readOnly={true}
          value={item?.name ? `${index}. ${item?.name}` : index}
          placeholder={"Click here to start"}
          onFocus={onFocusInput}
        />
        <InputRightElement width="4.5rem">
          <IconButton
            size="sm"
            fontSize="sm"
            variant="ghost"
            color="current"
            colorScheme="teal"
            onClick={onEdit}
            icon={<FaEdit />}
            aria-label={`Edit`}
          />
          <IconButton
            size="sm"
            fontSize="sm"
            variant="ghost"
            color="current"
            colorScheme="teal"
            onClick={onRemove}
            icon={<FaTrash />}
            aria-label={`Remove`}
          />
        </InputRightElement>
      </InputGroup>
    </ListItem>
  );
};
