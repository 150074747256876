import { SelectField } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { UserStatus, UserStatusValues } from "../../../../entities/user";

interface StatusSelectProps {
  selected: UserStatus;
  onChange: (evt: ChangeEvent<HTMLSelectElement>) => void;
}
export const StatusSelect = ({ selected, onChange }: StatusSelectProps) => {
  return (
    <SelectField
      placeholder="Select the status"
      onChange={onChange}
      defaultValue={selected || UserStatusValues.PENDING}
    >
      {Object.keys(UserStatusValues).map((status: string) => {
        return (
          <option value={status} key={status}>
            {UserStatusValues[status as UserStatus]}
          </option>
        );
      })}
    </SelectField>
  );
};
