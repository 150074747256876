import { Tooltip, HStack, VStack, Switch } from "@chakra-ui/react";
import { FaRoad, FaStop } from "react-icons/fa";

interface ToggleRoutePointsProp {
  showRoutePoints: boolean;
  setShowRoutePoints: (showRoutePoints: boolean) => void;
  right?: string;
  bottom?: string;
  label?: string;
  icon?: "route" | "point";
}

export const ToggleRoutePoints = ({
  showRoutePoints,
  setShowRoutePoints,
  right,
  bottom,
  label,
  icon,
}: ToggleRoutePointsProp) => {
  const toogleRoutePoints = () => {
    setShowRoutePoints(!showRoutePoints);
  };

  return (
    <VStack
      position="absolute"
      right={right || "15px"}
      bottom={bottom || "120px"}
      zIndex={999}
      justifyContent="space-between"
      alignContent="center"
      alignSelf="center"
      borderTopRadius={5}
    >
      <Tooltip label={label || "Show/Hide Route Points"} placement="top">
        <HStack
          background="#FFFB"
          paddingTop="4px"
          paddingBottom="4px"
          paddingLeft="8px"
          paddingRight="8px"
        >
          {icon === "point" ? <FaStop /> : <FaRoad />}
          <Switch
            id="enableRecording"
            isChecked={showRoutePoints}
            onChange={toogleRoutePoints}
          />{" "}
          Route Points
        </HStack>
      </Tooltip>
    </VStack>
  );
};
