import { MediaAudio } from "./audio";
import { MediaImage } from "./image";
import { MediaVideo } from "./video";

const medias = {
  audio: MediaAudio,
  image: MediaImage,
  video: MediaVideo,
};

export default medias;
