import { atom } from "recoil";
import { City, Country, Language, Tag } from "../entities/reference";

export const referenceData = atom<{
  countries: Country[];
  cities: City[];
  languages: Language[];
  tags: Tag[];
}>({
  key: "Reference",
  default: {
    countries: [],
    cities: [],
    languages: [],
    tags: [],
  },
});
