import { Button, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useRef } from "react";
import { FaArrowLeft, FaChartBar, FaInfo, FaRoute } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

interface TourMenuProps {
  closeMenu: () => void;
}
export const TourMenu = ({ closeMenu }: TourMenuProps) => {
  const navigate = useNavigate();
  const btnRef = useRef(null);
  const bgMenu = useColorModeValue("gray.300", "blackAlpha.500");
  const { tourId } = useParams();

  return (
    <VStack
      width="80px"
      bg={bgMenu}
      justifyContent="space-between"
      alignContent="center"
      paddingTop="4px"
      paddingBottom="4px"
      alignSelf="center"
      borderLeftRadius={5}
    >
      {!!tourId && (
        <VStack>
          <Button
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            ref={btnRef}
            colorScheme="teal"
            onClick={() => {
              navigate("/dashboard");
            }}
            aria-label={`Back to Home`}
          >
            <VStack>
              <FaArrowLeft />
              <Text fontSize="xs">Home</Text>
            </VStack>
          </Button>
          <Button
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            ref={btnRef}
            colorScheme="teal"
            onClick={() => {
              closeMenu();
              navigate(`/tour/${tourId}`);
            }}
            aria-label={`Tour Dashboard`}
            disabled={!tourId}
          >
            <VStack>
              <FaChartBar />
              <Text fontSize="xs">Dashboard</Text>
            </VStack>
          </Button>

          <Button
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            ref={btnRef}
            colorScheme="teal"
            onClick={() => {
              closeMenu();
              navigate(`/tour/${tourId}/details`);
            }}
            aria-label={`Tour Details`}
            disabled={!tourId}
          >
            <VStack>
              <FaInfo />
              <Text fontSize="xs">Details</Text>
            </VStack>
          </Button>
          <Button
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            ref={btnRef}
            colorScheme="teal"
            onClick={() => {
              closeMenu();
              navigate(`/tour/${tourId}/builder`);
            }}
            aria-label={`Builder`}
            disabled={!tourId}
          >
            <VStack>
              <FaRoute />
              <Text fontSize="xs">Builder</Text>
            </VStack>
          </Button>
        </VStack>
      )}
    </VStack>
  );
};
