import { ChangeEvent } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Highlight,
  Tooltip,
  Switch,
  HStack,
} from "@chakra-ui/react";
import { useField } from "formik";
import { FaInfoCircle } from "react-icons/fa";

interface SwitchFieldProps {
  name: string;
  label: string;
  required?: boolean;
  description?: string;
}

export const SwitchField = ({
  name,
  label,

  required,
  description,
}: SwitchFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const drawHelper = () =>
    description && (
      <Box display="inline-block" marginRight="4px" position="relative">
        <Tooltip hasArrow label={description} bg="gray.300" color="black">
          <span>
            <FaInfoCircle
              style={{
                cursor: "pointer",
              }}
            />
          </span>
        </Tooltip>
      </Box>
    );

  const drawRequired = () =>
    required && (
      <Highlight
        query="(required)"
        styles={{ px: "1", py: "1", bg: "orange.100", fontSize: "10px" }}
      >
        (required)
      </Highlight>
    );

  const changeSwitch = (_event: ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(!field.value);
  };

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <HStack>
        <FormLabel htmlFor={field.name} style={{ position: "relative" }}>
          {drawHelper()}
          {`${label}`}
          {drawRequired()}
        </FormLabel>
        <Switch
          size="sm"
          padding={1}
          isChecked={field.value}
          borderRadius="full"
          variant="solid"
          colorScheme="gray"
          onChange={changeSwitch}
        />
      </HStack>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
