import {
  Divider,
  HStack,
  Link,
  List,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { LatLng, Map } from "leaflet";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { PointDetails } from "../../../../../entities/pointDetails";
import { calculate2PointsDistance } from "../../utils/calculate2PointsDistance";
import { HelpPopup } from "../help";
import { DataPointMap } from "./dataPointMap";

const reorder = (
  list: PointDetails[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface PointsMenuProps {
  dataPoints: PointDetails[];
  setDataPoints: (dataPoints: PointDetails[]) => void;
  map: Map;
  updateDataPoint?: (poi: PointDetails) => void;
  removeDataPoint: (index: number) => () => void;
  onEditPointLocation: (index: number) => void;
  onClickEditPointDetails: (index: number) => void;
  setMovingRoutePoint: (index: number | null) => void;
  setInsertAfterThis: (index: number | null) => void;
  insertAfterThis: number | null;
  distance: number;
  setDistance: (distance: number) => void;
  showLabel: boolean;
  toggleLabel: () => void;
  title?: string;
}

export const PointsMenu = ({
  dataPoints,
  removeDataPoint,
  onEditPointLocation,
  onClickEditPointDetails,
  setDataPoints,
  setMovingRoutePoint,
  setInsertAfterThis,
  insertAfterThis,
  map,
  distance,
  setDistance,
  showLabel,
  toggleLabel,
  title,
}: PointsMenuProps) => {
  const [openHelp, setOpenHelp] = useState(false);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newDataPoints = reorder(
      dataPoints,
      result.source.index,
      result.destination.index
    );

    setDataPoints(newDataPoints);
    setMovingRoutePoint(null);
  };

  const onDragStart = (initial: DragStart) => {
    setMovingRoutePoint(initial.source.index);
  };

  useEffect(() => {
    const d = dataPoints.reduce(
      (
        acc: { lastPoint: PointDetails | null; distance: number },
        dataPoint: PointDetails
      ) => {
        if (acc.lastPoint === null) {
          acc.lastPoint = dataPoint;
          return acc;
        }

        acc.distance += calculate2PointsDistance(
          new LatLng(
            parseFloat(acc.lastPoint.location!.lat),
            parseFloat(acc.lastPoint.location!.lng)
          ),
          new LatLng(
            parseFloat(dataPoint.location!.lat),
            parseFloat(dataPoint.location!.lng)
          )
        );
        acc.lastPoint = dataPoint;
        return acc;
      },
      { lastPoint: null, distance: 0 }
    );

    setDistance(d.distance);
  }, [dataPoints, setDistance]);

  const onClickInsertAfterThis = (index: number) => () => {
    if (index === insertAfterThis) {
      setInsertAfterThis(null);
    } else {
      setInsertAfterThis(index);
    }
  };

  const closeHelp = () => {
    setOpenHelp(false);
  };
  return (
    <VStack
      position="absolute"
      left="100px"
      top="10px"
      zIndex={999}
      width="300px"
      height="auto"
      maxHeight="50%"
    >
      <HStack
        justifyContent="space-between"
        width="100%"
        height="32px"
        backgroundColor="#FFFFFF88"
      >
        <Link fontSize="sm" onClick={() => setOpenHelp(true)} fontWeight="bold">
          Map Help
        </Link>
        <Text fontSize="xs">
          Show #:
          <Switch
            size="sm"
            marginLeft={1}
            checked={showLabel}
            onChange={toggleLabel}
          />
        </Text>
        <Text fontSize="sm">Distance: {distance}m</Text>
        <HelpPopup isOpen={openHelp} onClose={closeHelp} />
      </HStack>
      {title && (
        <>
          <Divider />
          <HStack
            justifyContent="space-between"
            width="100%"
            height="32px"
            backgroundColor="#FFFFFF88"
          >
            <Text fontSize="sm" fontWeight="bold" width="100%" textAlign="left">
              {title}
            </Text>
          </HStack>
        </>
      )}
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="PointDetailssContainer">
          {(provided, _snapshot) => (
            <List
              maxHeight="calc(100% - 32px)"
              overflowY="scroll"
              width="100%"
              marginTop="0px !important"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {dataPoints.map((dataPoint, index) => (
                <Draggable
                  draggableId={String(index)}
                  index={index}
                  key={index}
                >
                  {(provided, _snapshot) => (
                    <DataPointMap
                      item={dataPoint}
                      index={index}
                      key={index}
                      map={map}
                      provided={provided}
                      onRemove={removeDataPoint(index)}
                      onEditPointLocation={() => onEditPointLocation(index)}
                      onEdit={() => onClickEditPointDetails(index)}
                      onMouseOver={() => setMovingRoutePoint(index)}
                      onMouseLeave={() => setMovingRoutePoint(null)}
                      onClickInsertAfterThis={onClickInsertAfterThis(index)}
                      insertAfterThis={insertAfterThis}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </VStack>
  );
};
