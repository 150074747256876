import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
} from "@chakra-ui/react";
import { LatLng } from "leaflet";
import { ChangeEvent, useState } from "react";
import { FaPlus } from "react-icons/fa";

interface AddPointFromLinkPopupProps {
  isOpen: boolean;
  onClose: (refresh?: boolean) => void;
  onAddRoutePointClick: (name?: string) => void;
  onAddStopPointClick: (name?: string) => void;
  onAddPoiClick: (name?: string) => void;
  setSelectedLocation: (latLng: LatLng | null) => void;
}

const extractLatLng = (text: string) => {
  // https://www.google.com/maps/d/u/0/viewer?mid=1fz62WSxVpZc2lbGy2o_EnucFhTw&hl=es&ll=41.38278600000002%2C2.1755719999999856&z=18
  const positionLL = text.indexOf("&ll=");

  if (!positionLL) {
    return "";
  }

  // This will contain: &ll=41.38278600000002%2C2.1755719999999856&z=18
  const extracted = text.substring(text.indexOf("&ll=") + 4);
  const positionEnd = extracted.indexOf("&");

  return extracted.substring(
    0,
    positionEnd > -1 ? positionEnd : extracted.length
  );
};

export const AddPointFromLinkPopup = ({
  isOpen,
  onClose,
  onAddRoutePointClick,
  onAddStopPointClick,
  onAddPoiClick,
  setSelectedLocation,
}: AddPointFromLinkPopupProps) => {
  const [type, setType] = useState("route");
  const [linkText, setLinkText] = useState("");
  const [nameText, setNameText] = useState("");
  const [previewPoint, setPreviewPoint] = useState("");

  const onChangeType = (evt: ChangeEvent<HTMLSelectElement>) => {
    setType(evt.target.value);
  };

  const onChangeLinkText = (evt: ChangeEvent<HTMLInputElement>) => {
    setLinkText(evt.target.value);
    const extractedCoordinates = extractLatLng(evt.target.value);
    setPreviewPoint(extractedCoordinates);

    const latLng = extractedCoordinates.split("%2C");
    if (extractedCoordinates === "" || latLng.length === 0) {
      return;
    }

    setSelectedLocation(new LatLng(+latLng[0], +latLng[1]));
  };

  const onChangeName = (evt: ChangeEvent<HTMLInputElement>) => {
    setNameText(evt.target.value);
  };

  const onClickSave = () => {
    const latLng = previewPoint.split("%2C");
    if (previewPoint === "" || latLng.length === 0) {
      return;
    }

    if (type === "route") {
      onAddRoutePointClick(nameText);
      onClose();
      return;
    }

    if (type === "stop") {
      onAddRoutePointClick(nameText);
      onClose();
      return;
    }

    onAddPoiClick(nameText);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalContent>
        <ModalHeader>Add Point from Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="type" style={{ position: "relative" }}>
              Type of Point
            </FormLabel>
            <Select name="type" onChange={onChangeType} value={type}>
              <option value="poi">Point of Interest</option>
              <option value="route">Route Point</option>
              <option value="stop">Stop Point</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="link" style={{ position: "relative" }}>
              Link (URL)
            </FormLabel>
            <Input name="link" onChange={onChangeLinkText} value={linkText} />
            <Text>{previewPoint || "Not found"}</Text>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="name" style={{ position: "relative" }}>
              Name
            </FormLabel>
            <Input name="name" onChange={onChangeName} value={nameText} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            disabled={!previewPoint.length}
            onClick={onClickSave}
          >
            <FaPlus /> <Text marginLeft="4px">Add</Text>
          </Button>
          <Button variant="secondary" ml={3} onClick={() => onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
