import {
  // Badge,
  Stat,
  // StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { TourWrapper } from "./wrapper";
import { useRecoilState } from "recoil";
import { fullTour } from "../../state/fullTour";

export const Tour = () => {
  const [fullTourValue] = useRecoilState(fullTour);

  return (
    <TourWrapper title={`Dashboard`}>
      <StatGroup
        border="1px solid #ccc"
        borderRadius={10}
        margin={4}
        padding={4}
      >
        <Stat>
          <StatLabel>Tours Pending</StatLabel>
          <StatNumber>{fullTourValue.stats?.pending ?? "-"}</StatNumber>
          {/* <StatHelpText>
            <StatArrow type="decrease" />
            -10% Compared with last month
          </StatHelpText> */}
        </Stat>
        <Stat>
          <StatLabel>Tours Playing</StatLabel>
          <StatNumber>{fullTourValue.stats?.played ?? "-"}</StatNumber>
          {/* <StatHelpText>
            <StatArrow />
            0%
          </StatHelpText> */}
        </Stat>
        <Stat>
          <StatLabel>Tours Completed</StatLabel>
          <StatNumber>{fullTourValue.stats?.completed ?? "-"}</StatNumber>
          {/* <StatHelpText>
            <StatArrow type="decrease" />
            -10% Compared with last month
          </StatHelpText> */}
        </Stat>
        <Stat>
          <StatLabel>Tours Cancelled</StatLabel>
          <StatNumber>{fullTourValue.stats?.cancelled ?? "-"}</StatNumber>
          {/* <StatHelpText>
            <StatArrow />
            0%
          </StatHelpText> */}
        </Stat>
      </StatGroup>
      <StatGroup
        border="1px solid #ccc"
        borderRadius={10}
        margin={4}
        padding={4}
      >
        <Stat>
          <StatLabel>Visits</StatLabel>
          <StatNumber>{fullTourValue.totalVisits ?? "-"}</StatNumber>
          {/* We need to save data per month to calculate */}
          {/* <StatHelpText>
            <StatArrow type="increase" />
            13.36%
          </StatHelpText> */}
        </Stat>
        <Stat>
          <StatLabel>Saved</StatLabel>
          <StatNumber>-</StatNumber>
          <StatHelpText>
            We don't have information about this yet
            {/* <StatArrow />
            0% */}
          </StatHelpText>
        </Stat>
      </StatGroup>
      <StatGroup
        border="1px solid #ccc"
        borderRadius={10}
        margin={4}
        padding={4}
      >
        <Stat>
          <StatLabel>Reviews</StatLabel>
          <StatNumber>{fullTourValue.totalVotes ?? "-"}</StatNumber>
          <StatHelpText># of reviews</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Score</StatLabel>
          <StatNumber>{fullTourValue.score ?? "-"}</StatNumber>
          <StatHelpText>
            We don't have enough data to show
            {/* <StatArrow type="decrease" /> */}
            {/* Last votation was lower than average */}
          </StatHelpText>
        </Stat>
      </StatGroup>
      <StatGroup
        border="1px solid #ccc"
        borderRadius={10}
        margin={4}
        padding={4}
      >
        <Stat>
          <StatLabel>Sales</StatLabel>
          <StatNumber>{fullTourValue.stats?.sales ?? "-"}</StatNumber>
          {/* <StatHelpText>
            <StatArrow type="decrease" />
            -10% Compared with last month
          </StatHelpText> */}
        </Stat>
        <Stat>
          <StatLabel>Cancelled Sales</StatLabel>
          <StatNumber>{fullTourValue.stats?.cancelledSales ?? "-"}</StatNumber>
          {/* <StatHelpText>
            <StatArrow />
            0%
          </StatHelpText> */}
        </Stat>
      </StatGroup>
    </TourWrapper>
  );
};
