import { Box, IconButton, Spinner } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaTrash } from "react-icons/fa";

export interface MediaWrapperProps {
  name: string;
  src: string;
  loading?: boolean;
  onClickRemove?: () => void;
  children?: ReactNode;
}

export const MediaWrapper = ({
  loading,
  onClickRemove,
  children,
}: MediaWrapperProps) => {
  return (
    <Box position="relative" width="200px" height="200px">
      {children}
      {loading && (
        <Spinner
          position="absolute"
          bottom={2}
          right={2}
          background="#ffffff33"
        />
      )}
      {!loading && (
        <IconButton
          icon={<FaTrash />}
          aria-label="Remove"
          position="absolute"
          bottom={2}
          right={2}
          onClick={onClickRemove}
        />
      )}
    </Box>
  );
};
