import { Button, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useRef } from "react";
import {
  FaArrowLeft,
  FaFileImport,
  FaFlag,
  FaFlagCheckered,
  FaList,
  FaUsers,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface AdminMenuProps {
  closeMenu: () => void;
}

export const AdminMenu = ({ closeMenu }: AdminMenuProps) => {
  const navigate = useNavigate();
  const btnRef = useRef(null);
  const bgMenu = useColorModeValue("gray.300", "blackAlpha.500");

  return (
    <VStack
      width="80px"
      bg={bgMenu}
      justifyContent="space-between"
      alignContent="center"
      paddingTop="4px"
      paddingBottom="4px"
      alignSelf="center"
      borderLeftRadius={5}
    >
      <VStack>
        <Button
          paddingTop="4px"
          paddingBottom="4px"
          marginBottom="8px"
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          onClick={() => {
            closeMenu();
          }}
          aria-label={`Back to Home`}
        >
          <VStack>
            <FaArrowLeft />
            <Text fontSize="xs">Home</Text>
          </VStack>
        </Button>

        <Button
          paddingTop="4px"
          paddingBottom="4px"
          marginBottom="8px"
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          onClick={() => {
            navigate("/admin/users");
          }}
          aria-label={`Manage Users`}
        >
          <VStack>
            <FaUsers />
            <Text fontSize="xs">Users</Text>
          </VStack>
        </Button>

        <Button
          paddingTop="4px"
          paddingBottom="4px"
          marginBottom="8px"
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          onClick={() => {
            navigate("/admin/tours");
          }}
          aria-label={`Manage Tours`}
        >
          <VStack>
            <FaList />
            <Text fontSize="xs">Tours</Text>
          </VStack>
        </Button>

        <Button
          paddingTop="4px"
          paddingBottom="4px"
          marginBottom="8px"
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          onClick={() => {
            navigate("/admin/countries");
          }}
          aria-label={`Manage Countries`}
        >
          <VStack>
            <FaFlag />
            <Text fontSize="xs">Countries</Text>
          </VStack>
        </Button>

        <Button
          paddingTop="4px"
          paddingBottom="4px"
          marginBottom="8px"
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          onClick={() => {
            navigate("/admin/cities");
          }}
          aria-label={`Manage Cities`}
        >
          <VStack>
            <FaFlagCheckered />
            <Text fontSize="xs">Cities</Text>
          </VStack>
        </Button>

        <Button
          paddingTop="4px"
          paddingBottom="4px"
          marginBottom="8px"
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          ref={btnRef}
          colorScheme="teal"
          onClick={() => {
            navigate("/admin/importer");
          }}
          aria-label={`Import Tour from JSON`}
        >
          <VStack>
            <FaFileImport />
            <Text fontSize="xs">Import</Text>
          </VStack>
        </Button>
      </VStack>
    </VStack>
  );
};
