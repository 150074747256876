import {
  cacheExchange,
  CombinedError,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Operation,
} from "urql";
import { tokenManagement } from "../auth/token";

const ERROR_UNAUTHORIZED = "[GraphQL] Unauthorized";

const getFetchOptions = () => {
  const { token } = tokenManagement.getAuthContext();
  if (token) {
    return { headers: { Authorization: `Bearer ${token}` } };
  }

  return {};
};

export const gqlClient = createClient({
  url: `${process.env.REACT_APP_SERVER_API || "http://localhost:8080"}/graphql`,
  fetchOptions: getFetchOptions,
  requestPolicy: "network-only",
  exchanges: [
    dedupExchange,
    cacheExchange,
    errorExchange({
      onError: (error: CombinedError, operation: Operation) => {
        if (error.message === ERROR_UNAUTHORIZED) {
          tokenManagement.removeAuthToken();
          window.location.href = "/";
        }
      },
    }),
    fetchExchange,
  ],
});
