import {
  Container,
  Heading,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SelectField,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { FaQuestion } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useMutation, useQuery } from "urql";
import { gqlTourMutationUpdateStatus } from "../../api/mutations/tour";
import { gqlTourByIdQuery } from "../../api/queries/tour";
import { TourStatus } from "../../entities/tour";
import { fullTour } from "../../state/fullTour";
import { capitalizeFirstLetter } from "../../utils/strings";
import { BasePrivate } from "../basePrivate";

interface TourWrapperProps {
  title: string;
  isLoading?: boolean;
  children: ReactNode;
}

const StatusOptions: TourStatus[] = [
  "DRAFT",
  "PREREADY",
  "PREPUBLISHED",
  "READY",
  "PUBLISHED",
  "REJECTED",
];

const EditableStatuses: TourStatus[] = ["DRAFT", "PREREADY", "READY"];

export const TourWrapper = ({
  title,
  isLoading,
  children,
}: TourWrapperProps) => {
  const { tourId } = useParams();
  const navigate = useNavigate();
  const [fullTourValue, setFullTourValue] = useRecoilState(fullTour);
  const [isNewDataLoading, setIsNewDataLoading] = useState(
    fullTourValue.id !== tourId
  );
  const [, executeMutationUpdateStatusTour] = useMutation(
    gqlTourMutationUpdateStatus
  );
  const [{ data, fetching }] = useQuery({
    query: gqlTourByIdQuery,
    variables: {
      tourId,
    },
    pause: !!fullTourValue.id && fullTourValue.id === tourId,
  });

  if (!tourId) {
    navigate("/");
  }

  useEffect(() => {
    if (!fetching && data) {
      setFullTourValue(data.tour);
      setIsNewDataLoading(false);
    }
  }, [setFullTourValue, fetching, data]);

  const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const newStatus = (e.target.value || "DRAFT").toUpperCase() as TourStatus;

    if (EditableStatuses.includes(newStatus)) {
      executeMutationUpdateStatusTour({
        updateTourStatusInput: {
          id: tourId,
          status: (e.target.value || "DRAFT").toUpperCase(),
        },
      });
    } else {
      e.target.value = fullTourValue.status || "DRAFT";
      alert("You can't Approve/Reject Tours");
    }
  };

  return (
    <BasePrivate
      header={
        ((isLoading || isNewDataLoading || fetching || !fullTourValue.id) && (
          <Spinner />
        )) || (
          <HStack
            width="100%"
            justifyContent="space-between"
            paddingRight={4}
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Heading as="h1" fontSize="l">
              {`${fullTourValue.name} - ${title}`}
            </Heading>
            <HStack zIndex={999}>
              <Text fontSize="sm">Status:</Text>
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    size="xs"
                    colorScheme="blue"
                    icon={<FaQuestion />}
                    aria-label="Help"
                    marginRight={2}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverCloseButton />
                  <PopoverHeader>Help: Tour Status</PopoverHeader>
                  <PopoverBody>
                    <Text fontSize="sm" textAlign="left">
                      We review all the tours to assure the quality of the
                      information.
                    </Text>
                    <Text fontSize="sm" textAlign="left">
                      Once you finish creating the Tour, switch the status to:
                      Preready or Ready.
                    </Text>
                    <Text fontSize="sm" textAlign="left">
                      You will receive a confirmation email once it is approved
                      and published.
                    </Text>
                    <Text fontSize="sm" textAlign="left">
                      Otherwise, we will send you an email with reason(s) to
                      have it rejected.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Tooltip label="Change the status">
                <SelectField
                  marginLeft="16px"
                  onChange={onChangeStatus}
                  fontSize="md"
                  padding={1}
                  borderRadius={3}
                  defaultValue={fullTourValue.status || StatusOptions[0]}
                >
                  {StatusOptions.map((status: TourStatus) => (
                    <option key={status} value={status}>
                      {capitalizeFirstLetter(status)}
                    </option>
                  ))}
                </SelectField>
              </Tooltip>
            </HStack>
          </HStack>
        )
      }
      title=""
    >
      <HStack
        alignSelf="stretch"
        justifyItems="flex-end"
        flexGrow={1}
        height="100%"
        maxHeight="100%"
      >
        <Container
          maxWidth="100%"
          flexGrow="1"
          margin={0}
          padding={0}
          style={{ WebkitMarginStart: 0 }}
          alignSelf="stretch"
        >
          {isLoading || isNewDataLoading || fetching || !fullTourValue.id ? (
            <Spinner />
          ) : (
            <>{children}</>
          )}
        </Container>
      </HStack>
    </BasePrivate>
  );
};
