import { isEqual } from "lodash";
import { Box, Spinner, VStack } from "@chakra-ui/react";
import { LatLng, LatLngExpression, Map } from "leaflet";
import { useCallback, useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useRecoilState } from "recoil";
import { TourWrapper } from "../wrapper";
import { ContextMenu } from "./components/contextMenu";
import { MapEvents } from "./components/mapEvents";
import { PointsMenu } from "./components/routePointsMenu";
import { PositionMarker } from "./components/positionMarker/positionMarker";
import { ToolsMenu } from "./components/toolsMenu";
import { RouteDrawer } from "./components/routeDrawer";
import { PoiDrawer } from "./components/poiDrawer";
import { PointDetailsPopup } from "./components/pointDetailsPopup";
import { LanguageDetails, PointDetails } from "../../../entities/pointDetails";
import { useMutation } from "urql";
import { gqlTourMutationUpdate } from "../../../api/mutations/tour";
import { fullTour } from "../../../state/fullTour";
import { mapDataPoints } from "./utils/mapDataPoints";
import { DEFAULT_LANGUAGE_CODE } from "../details";
import { AddPointFromLinkPopup } from "./components/addFromLinkPopup";
import { AddPointFromCoordinatesPopup } from "./components/addFromCoordinatesPopup";
import { RecordMenu } from "./components/recordMenu";
import { ToggleRoutePoints } from "./components/toggleRoutePoints";
import { isFrozenStatus } from "../../../entities/tour";
import { StopDrawer } from "./components/stopDrawer";

interface MapActions {
  type: "editRoute" | "none";
  index?: number;
  active?: boolean;
}

const createNewPoint = (
  latlng: LatLng,
  languages: string[],
  type: PointDetails["type"] = "ROUTE_POINT",
  name?: string
): PointDetails => ({
  location: {
    lat: String(latlng.lat),
    lng: String(latlng.lng),
  },
  type,
  languages: languages.map(
    (lang: string): LanguageDetails => ({
      languageCode: lang,
      description: "",
      images: [],
      videos: [],
      audio: "",
    })
  ),
  name: typeof name === "string" ? name : "",
});

export const Builder = () => {
  const [fullTourValue, setFullTourValue] = useRecoilState(fullTour);
  const [, executeMutationUpdateTour] = useMutation(gqlTourMutationUpdate);
  const [map, setMap] = useState<Map | null>(null);
  const [routeDataPoints, setRouteDataPoints] = useState<PointDetails[]>(
    fullTourValue.routePoints || []
  );
  const [stopDataPoints, setStopDataPoints] = useState<PointDetails[]>(
    fullTourValue.stopPoints || []
  );
  const [pointOfInterests, setPointOfInterests] = useState<PointDetails[]>(
    fullTourValue.pointsOfInterest || []
  );
  const [enabledLocation, setEnabledLocation] = useState(false);
  const [enabledRecording, setEnabledRecording] = useState(false);
  const [showRoutePoints, setShowRoutePoints] = useState(true);
  const [showStopPoints, setShowStopPoints] = useState(false);
  const [distance, setDistance] = useState(0);
  const [addRouteFromLink, setAddRouteFromLink] = useState(false);
  const [addRouteFromCoordinates, setAddRouteFromCoordinates] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<LatLng | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LatLng | null>(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    left: "-400px",
    top: "-400px",
  });
  const [activeMapAction, setActiveMapAction] = useState<MapActions>({
    type: "none",
  });
  const [ignoreClickEvent, setIgnoreClickEvent] = useState(false);
  const [selectedRoutePoint, setSelectedRoutePoint] = useState<number | null>(
    null
  );
  const [selectedStopPoint, setSelectedStopPoint] = useState<number | null>(
    null
  );
  const [sortingRoutePoint, setSortingRoutePoint] = useState<number | null>(
    null
  );
  const [sortingStopPoint, setSortingStopPoint] = useState<number | null>(null);
  const [insertRouteAfterThis, setInsertRouteAfterThis] = useState<
    number | null
  >(null);
  const [insertStopAfterThis, setInsertStopAfterThis] = useState<number | null>(
    null
  );
  const [selectedPointOfInterest, setSelectedPointOfInterest] = useState<
    number | null
  >(null);
  const [showScreenshotMask, setShowScreenshotMask] = useState(false);

  const setRoutePointsAndSave = (routePoints: PointDetails[]) => {
    setRouteDataPoints(routePoints);
    saveData(routePoints, stopDataPoints || [], pointOfInterests || []);
  };

  const setStopPointsAndSave = (stopPoints: PointDetails[]) => {
    setStopDataPoints(stopPoints);
    saveData(routeDataPoints || [], stopPoints, pointOfInterests || []);
  };

  const setPointOfInterestsAndSave = (pointOfInterest: PointDetails[]) => {
    setPointOfInterests(pointOfInterest);
    saveData(routeDataPoints || [], stopDataPoints || [], pointOfInterest);
  };

  useEffect(() => {
    if (isFrozenStatus(fullTourValue.status!)) {
      alert("The tour is read only because has been published already.");
    }
  }, [fullTourValue.status]);

  useEffect(() => {
    if (!routeDataPoints && !!fullTourValue.id) {
      setRouteDataPoints(fullTourValue.routePoints || []);
      setStopDataPoints(fullTourValue.stopPoints || []);
      setPointOfInterests(fullTourValue.pointsOfInterest || []);
    }
  }, [
    fullTourValue,
    setRouteDataPoints,
    setStopDataPoints,
    setPointOfInterests,
    routeDataPoints,
  ]);

  const saveData = useCallback(
    async (
      newRoutePoints: PointDetails[],
      newStopPoints: PointDetails[],
      newPointOfInterest: PointDetails[]
    ) => {
      if (!fullTourValue.id || isFrozenStatus(fullTourValue.status!)) {
        return;
      }

      if (
        !isEqual(fullTourValue.routePoints, newRoutePoints) ||
        !isEqual(fullTourValue.stopPoints, newStopPoints) ||
        !isEqual(fullTourValue.pointsOfInterest, newPointOfInterest)
      ) {
        const updateTourInput = {
          id: fullTourValue.id,
          routePoints: mapDataPoints(newRoutePoints || []),
          stopPoints: mapDataPoints(newStopPoints || []),
          pointsOfInterest: mapDataPoints(newPointOfInterest || []),
          tourDistance: String(distance),
        };

        executeMutationUpdateTour({
          updateTourInput,
        }).then(() => {
          setFullTourValue({
            ...structuredClone(fullTourValue),
            routePoints: newRoutePoints || [],
            stopPoints: newStopPoints || [],
            pointsOfInterest: newPointOfInterest || [],
          });
        });
      }
    },
    [fullTourValue, distance, setFullTourValue, executeMutationUpdateTour]
  );

  const removeRouteDataPoint = (index: number) => () => {
    const newRouteDataPoints = structuredClone(routeDataPoints).filter(
      (_: PointDetails, i: number) => i !== index
    );
    setRoutePointsAndSave(newRouteDataPoints);
    onCloseEditRoutePoint();
  };

  const removeStopDataPoint = (index: number) => () => {
    const newStopDataPoints = structuredClone(stopDataPoints).filter(
      (_: PointDetails, i: number) => i !== index
    );
    setStopPointsAndSave(newStopDataPoints);
    onCloseEditStopPoint();
  };

  const removePointOfInterest = (index: number) => () => {
    const newPointOfInterests = structuredClone(pointOfInterests).filter(
      (_: PointDetails, i: number) => i !== index
    );
    setPointOfInterestsAndSave(newPointOfInterests);
    onCloseEditPointOfInterest();
  };

  const editRoute = (index: number, latlng: LatLng) => {
    const newRouteDataPoints = [...(routeDataPoints || [])];
    if (index > -1) {
      newRouteDataPoints[index].location = {
        lat: String(latlng.lat),
        lng: String(latlng.lng),
      };

      setRoutePointsAndSave(newRouteDataPoints);
      setActiveMapAction({
        type: "none",
        index: undefined,
        active: false,
      });
      return;
    }

    newRouteDataPoints.push(
      createNewPoint(
        latlng,
        fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE]
      )
    );
    setRoutePointsAndSave(newRouteDataPoints);
  };

  const editStop = (index: number, latlng: LatLng) => {
    const newStopDataPoints = [...(stopDataPoints || [])];
    if (index > -1) {
      newStopDataPoints[index].location = {
        lat: String(latlng.lat),
        lng: String(latlng.lng),
      };

      setStopPointsAndSave(newStopDataPoints);
      setActiveMapAction({
        type: "none",
        index: undefined,
        active: false,
      });
      return;
    }

    newStopDataPoints.push(
      createNewPoint(
        latlng,
        fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE]
      )
    );
    setStopPointsAndSave(newStopDataPoints);
  };

  const moveHereRoutePoint = () => {
    if (
      insertRouteAfterThis !== null &&
      insertRouteAfterThis !== undefined &&
      selectedLocation
    ) {
      const newRouteDataPoints = JSON.parse(JSON.stringify(routeDataPoints));
      newRouteDataPoints[insertRouteAfterThis].location = {
        lat: String(selectedLocation.lat),
        lng: String(selectedLocation.lng),
      };

      setRoutePointsAndSave(newRouteDataPoints);
    }
    onContextMenuClose();
  };

  const moveHereStopPoint = () => {
    if (
      insertStopAfterThis !== null &&
      insertStopAfterThis !== undefined &&
      selectedLocation
    ) {
      const newStopDataPoints = JSON.parse(JSON.stringify(stopDataPoints));
      newStopDataPoints[insertStopAfterThis].location = {
        lat: String(selectedLocation.lat),
        lng: String(selectedLocation.lng),
      };

      setStopPointsAndSave(newStopDataPoints);
    }
    onContextMenuClose();
  };

  const onAddRoutePointClick = (nameOrEvent?: any, location?: LatLng) => {
    if (selectedLocation || location) {
      const newLocation: LatLng = selectedLocation || location!;
      const name: string | undefined =
        typeof nameOrEvent === "string" ? nameOrEvent : undefined;
      const newRouteDataPoints = [...(routeDataPoints || [])];

      if (
        !location &&
        insertRouteAfterThis &&
        insertRouteAfterThis < newRouteDataPoints.length - 1
      ) {
        newRouteDataPoints.splice(
          insertRouteAfterThis + 1,
          0,
          createNewPoint(
            newLocation,
            fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE],
            "ROUTE_POINT",
            name || ""
          )
        );
        setInsertRouteAfterThis(insertRouteAfterThis + 1);
      } else {
        const lastPoint: PointDetails | null = newRouteDataPoints.length
          ? newRouteDataPoints[newRouteDataPoints.length - 1]
          : null;
        if (
          lastPoint &&
          newLocation.lat === +lastPoint.location.lat &&
          newLocation.lng === +lastPoint.location.lng
        ) {
          return;
        }

        newRouteDataPoints.push(
          createNewPoint(
            newLocation,
            fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE],
            "ROUTE_POINT",
            name || ""
          )
        );
      }
      setRoutePointsAndSave(newRouteDataPoints);
    }
    onContextMenuClose();
  };

  const onAddStopPointClick = (nameOrEvent?: any, location?: LatLng) => {
    if (selectedLocation || location) {
      const newLocation: LatLng = selectedLocation || location!;
      const name: string | undefined =
        typeof nameOrEvent === "string" ? nameOrEvent : undefined;
      const newStopDataPoints = [...(stopDataPoints || [])];

      if (
        !location &&
        insertStopAfterThis &&
        insertStopAfterThis < newStopDataPoints.length - 1
      ) {
        newStopDataPoints.splice(
          insertStopAfterThis + 1,
          0,
          createNewPoint(
            newLocation,
            fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE],
            "ROUTE_POINT",
            name || ""
          )
        );
        setInsertRouteAfterThis(insertStopAfterThis + 1);
      } else {
        const lastPoint: PointDetails | null = newStopDataPoints.length
          ? newStopDataPoints[newStopDataPoints.length - 1]
          : null;
        if (
          lastPoint &&
          newLocation.lat === +lastPoint.location.lat &&
          newLocation.lng === +lastPoint.location.lng
        ) {
          return;
        }

        newStopDataPoints.push(
          createNewPoint(
            newLocation,
            fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE],
            "ROUTE_POINT",
            name || ""
          )
        );
      }
      setStopPointsAndSave(newStopDataPoints);
    }
    onContextMenuClose();
  };

  const onSaveEditRoutePoint =
    (index: number) => (pointDetails: PointDetails) => {
      const newRouteDataPoints = structuredClone(routeDataPoints);

      newRouteDataPoints[index] = pointDetails;

      setRoutePointsAndSave(newRouteDataPoints);
    };

  const onSaveEditStopPoint =
    (index: number) => (pointDetails: PointDetails) => {
      const newStopDataPoints = structuredClone(stopDataPoints);

      newStopDataPoints[index] = pointDetails;

      setStopPointsAndSave(newStopDataPoints);
    };

  const onSaveEditPoi = (index: number) => (pointDetails: PointDetails) => {
    const newPointOfInterests = structuredClone(pointOfInterests);

    newPointOfInterests[index] = pointDetails;

    setPointOfInterestsAndSave(newPointOfInterests);
  };

  const onPoiAddClick = (name?: string) => {
    if (selectedLocation) {
      const newPointOfInterests = structuredClone(pointOfInterests);
      newPointOfInterests.push(
        createNewPoint(
          selectedLocation,
          fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE],
          "POINT_OF_INTEREST",
          name || ""
        )
      );
      setPointOfInterestsAndSave(newPointOfInterests);
      setSelectedPointOfInterest(newPointOfInterests.length - 1);
    }
    onContextMenuClose();
  };

  // Popup control
  const onEditPointLocation = (index: number) => {
    setActiveMapAction({
      type: "editRoute",
      active: true,
      index: index,
    });
  };

  const onClickEditPointDetails = (index: number) => {
    setSelectedRoutePoint(index);
  };

  const onCloseEditRoutePoint = () => {
    setSelectedRoutePoint(null);
  };

  const onClickEditStopDetails = (index: number) => {
    setSelectedStopPoint(index);
  };

  const onCloseEditStopPoint = () => {
    setSelectedStopPoint(null);
  };

  const onClickEditPointOfInterest = (index: number) => {
    setSelectedPointOfInterest(index);
  };

  const onCloseEditPointOfInterest = () => {
    setSelectedPointOfInterest(null);
  };

  // Map Interactions
  const onContextMenuOpen = (e: any) => {
    setContextMenuPosition({
      left: e.containerPoint.x,
      top: e.containerPoint.y,
    });
    setSelectedLocation(e.latlng);
    setContextMenuOpen(true);
  };

  const onContextMenuClose = () => {
    setContextMenuPosition({
      left: "-400px",
      top: "-400px",
    });
    setSelectedLocation(null);
    setContextMenuOpen(false);
  };

  const mapActionHandlers = {
    editRoute,
    editStop,
  };

  const onMapClick = (e: any, _map: Map) => {
    if (ignoreClickEvent) {
      setIgnoreClickEvent(false);
      return;
    }
    const { type, active, index } = activeMapAction;

    if (type !== "none" && active) {
      mapActionHandlers[type](index !== undefined ? index : -1, e.latlng);
    }
  };

  const onAddRouteFromCoordinatesClick = () => {
    setAddRouteFromCoordinates(true);
  };

  const onAddRouteFromLinkClick = () => {
    setAddRouteFromLink(true);
  };

  const onCloseAddRouteFromCoordinates = () => {
    setAddRouteFromCoordinates(false);
  };

  const onCloseAddRouteFromLink = () => {
    setAddRouteFromLink(false);
  };

  const toogleLabels = () => setShowLabels(!showLabels);

  // Using leaflet-providers: https://leaflet-extras.github.io/leaflet-providers/preview/
  return (
    <TourWrapper title="Builder">
      {!fullTourValue.id ? (
        <Spinner />
      ) : (
        <VStack height="100%" width="100%" position="relative">
          {showScreenshotMask && (
            <Box
              position="fixed"
              zIndex="999999"
              left="0"
              right="0"
              bottom="0"
              top="0"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
              display="flex"
              onClick={() => setShowScreenshotMask(false)}
            >
              <Box width={500} height={500} border="1px solid red"></Box>
            </Box>
          )}
          <MapContainer
            center={
              routeDataPoints?.length
                ? ([
                    Number(routeDataPoints[0].location.lat),
                    Number(routeDataPoints[0].location.lng),
                  ] as LatLngExpression)
                : ([
                    Number(fullTourValue.city?.location.lat || "0"),
                    Number(fullTourValue.city?.location.lng || "0"),
                  ] as LatLngExpression)
            }
            minZoom={13}
            maxZoom={18}
            zoom={15}
            scrollWheelZoom={true}
          >
            <TileLayer
              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
              // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
              attribution="Google Maps"
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
            <MapEvents
              onClick={onMapClick}
              onContextMenu={onContextMenuOpen}
              setMap={setMap}
            />
            {enabledLocation && (
              <PositionMarker onLocationFound={setCurrentLocation} />
            )}
            <ToggleRoutePoints
              showRoutePoints={showRoutePoints}
              setShowRoutePoints={(value: boolean) => {
                setShowRoutePoints(value);
                setShowStopPoints(showStopPoints && !value);
              }}
            />
            <ToggleRoutePoints
              showRoutePoints={showStopPoints}
              setShowRoutePoints={(value: boolean) => {
                setShowStopPoints(value);
                setShowRoutePoints(showRoutePoints && !value);
              }}
              label="Show/Hide Stops"
              icon="point"
              bottom="160px"
            />
            {enabledLocation && (
              <RecordMenu
                enabledLocation={enabledLocation}
                enabledRecording={enabledRecording}
                setEnabledRecording={setEnabledRecording}
                onAddRouteStopClick={onAddRoutePointClick}
              />
            )}
            <ToolsMenu
              enabledLocation={enabledLocation}
              setEnabledLocation={setEnabledLocation}
              currentLocation={currentLocation}
            />
            {contextMenuOpen && (
              <ContextMenu
                onClose={onContextMenuClose}
                onRouteAddClick={onAddRoutePointClick}
                onStopAddClick={onAddStopPointClick}
                onPoiAddClick={onPoiAddClick}
                onAddRouteFromLinkClick={onAddRouteFromLinkClick}
                onAddRouteFromCoordinatesClick={onAddRouteFromCoordinatesClick}
                selectedRoutePoint={insertRouteAfterThis}
                selectedStopPoint={insertStopAfterThis}
                moveHereRoutePoint={moveHereRoutePoint}
                moveHereStopPoint={moveHereStopPoint}
                showScreenshotMask={showScreenshotMask}
                setShowScreenshotMask={setShowScreenshotMask}
                {...contextMenuPosition}
              />
            )}

            <StopDrawer
              dataPoints={stopDataPoints || []}
              movingRoutePoint={sortingStopPoint}
              setInsertAfterThis={setInsertStopAfterThis}
              insertAfterThis={insertStopAfterThis}
              showLabels={showLabels}
            />
            <RouteDrawer
              dataPoints={routeDataPoints || []}
              movingRoutePoint={sortingRoutePoint}
              setInsertAfterThis={setInsertRouteAfterThis}
              insertAfterThis={insertRouteAfterThis}
              showLabels={showLabels}
            />
            <PoiDrawer
              dataPoints={pointOfInterests || []}
              onClickEditPoi={onClickEditPointOfInterest}
            />
          </MapContainer>
          {map && showRoutePoints && !showScreenshotMask && (
            <PointsMenu
              map={map}
              title="Build the route to walk/bike/etc"
              dataPoints={routeDataPoints || []}
              setDataPoints={setRoutePointsAndSave}
              removeDataPoint={removeRouteDataPoint}
              onEditPointLocation={onEditPointLocation}
              onClickEditPointDetails={onClickEditPointDetails}
              insertAfterThis={insertRouteAfterThis}
              setInsertAfterThis={setInsertRouteAfterThis}
              setMovingRoutePoint={setSortingRoutePoint}
              distance={distance}
              setDistance={setDistance}
              showLabel={showLabels}
              toggleLabel={toogleLabels}
            />
          )}
          {map && showStopPoints && !showScreenshotMask && (
            <PointsMenu
              map={map}
              title="Add the stops and customize them"
              dataPoints={stopDataPoints || []}
              setDataPoints={setStopPointsAndSave}
              removeDataPoint={removeStopDataPoint}
              onEditPointLocation={onEditPointLocation}
              onClickEditPointDetails={onClickEditStopDetails}
              insertAfterThis={insertStopAfterThis}
              setInsertAfterThis={setInsertStopAfterThis}
              setMovingRoutePoint={setSortingStopPoint}
              distance={distance}
              setDistance={setDistance}
              showLabel={showLabels}
              toggleLabel={toogleLabels}
            />
          )}
          {!!routeDataPoints &&
            selectedRoutePoint !== null &&
            selectedRoutePoint >= 0 && (
              <PointDetailsPopup
                isOpen={selectedRoutePoint >= 0}
                pointDetails={routeDataPoints[selectedRoutePoint]}
                onClose={onCloseEditRoutePoint}
                type="route"
                index={selectedRoutePoint}
                blockedStatus={isFrozenStatus(fullTourValue.status!)}
                saveEditPoint={onSaveEditRoutePoint(selectedRoutePoint)}
                onRemovePoint={removeRouteDataPoint(selectedRoutePoint)}
              />
            )}
          {!!stopDataPoints &&
            selectedStopPoint !== null &&
            selectedStopPoint >= 0 && (
              <PointDetailsPopup
                isOpen={selectedStopPoint >= 0}
                pointDetails={stopDataPoints[selectedStopPoint]}
                onClose={onCloseEditStopPoint}
                type="stop"
                index={selectedStopPoint}
                blockedStatus={isFrozenStatus(fullTourValue.status!)}
                saveEditPoint={onSaveEditStopPoint(selectedStopPoint)}
                onRemovePoint={removeStopDataPoint(selectedStopPoint)}
              />
            )}
          {!!pointOfInterests &&
            selectedPointOfInterest !== null &&
            selectedPointOfInterest >= 0 && (
              <PointDetailsPopup
                isOpen={selectedPointOfInterest >= 0}
                pointDetails={pointOfInterests[selectedPointOfInterest]}
                onClose={onCloseEditPointOfInterest}
                type="poi"
                index={selectedPointOfInterest}
                blockedStatus={isFrozenStatus(fullTourValue.status!)}
                saveEditPoint={onSaveEditPoi(selectedPointOfInterest)}
                onRemovePoint={removePointOfInterest(selectedPointOfInterest)}
              />
            )}
          {addRouteFromLink && (
            <AddPointFromLinkPopup
              isOpen={addRouteFromLink}
              onClose={onCloseAddRouteFromLink}
              onAddRoutePointClick={onAddRoutePointClick}
              onAddStopPointClick={onAddStopPointClick}
              onAddPoiClick={onPoiAddClick}
              setSelectedLocation={setSelectedLocation}
            />
          )}

          {addRouteFromCoordinates && (
            <AddPointFromCoordinatesPopup
              isOpen={addRouteFromCoordinates}
              onClose={onCloseAddRouteFromCoordinates}
              onAddRoutePointClick={onAddRoutePointClick}
              onAddStopPointClick={onAddStopPointClick}
              onAddPoiClick={onPoiAddClick}
              setSelectedLocation={setSelectedLocation}
            />
          )}
        </VStack>
      )}
    </TourWrapper>
  );
};
