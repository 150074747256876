import axios from "axios";
import { tokenManagement } from "../auth/token";
import { Tour, TourStatus } from "../entities/tour";
import { UserStatus } from "../entities/user";

class AdminAPI {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  async getCities() {
    try {
      const { data } = await axios.get(`${this.url}/admin/api/cities`, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      return data;
    } catch {
      return {};
    }
  }

  async getCountries() {
    try {
      const { data } = await axios.get(`${this.url}/admin/api/countries`, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      return data;
    } catch {
      return {};
    }
  }

  async addUser(email: string, name: string, password: string) {
    return axios.post(
      `${this.url}/admin/api/users`,
      {
        name,
        email,
        password,
      },
      {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      }
    );
  }

  async addCountry(name: string, code: string) {
    return axios.post(
      `${this.url}/admin/api/countries`,
      {
        name,
        code,
      },
      {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      }
    );
  }

  async addCity(name: string, country: string, lat: string, lng: string) {
    return axios.post(
      `${this.url}/admin/api/cities`,
      {
        name,
        country,
        lat,
        lng,
      },
      {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      }
    );
  }

  async getUsers() {
    try {
      const { data } = await axios.get(`${this.url}/admin/api/users`, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      return data;
    } catch {
      return {};
    }
  }

  async updateUserStatus(id: string, status: UserStatus) {
    try {
      const { data } = await axios.put(
        `${this.url}/admin/api/users/${id}`,
        {
          status,
        },
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      return data;
    } catch {
      return {};
    }
  }

  async getTours() {
    try {
      const { data } = await axios.get(`${this.url}/admin/api/tours`, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      return data;
    } catch {
      return {};
    }
  }

  async getTourById(tourId: string) {
    try {
      const { data } = await axios.get(
        `${this.url}/admin/api/tours/${tourId}`,
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      return data;
    } catch {
      return {};
    }
  }

  async getProxyURL(url: string) {
    try {
      const { data } = await axios.post(
        `${this.url}/admin/api/url-proxy/`,
        {
          url,
        },
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      return data;
    } catch {
      return {};
    }
  }

  async updateTourStatus(id: string, status: TourStatus) {
    try {
      const { data } = await axios.put(
        `${this.url}/admin/api/tours/${id}/status`,
        {
          status,
        },
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      return data;
    } catch {
      return {};
    }
  }

  async createTour(tour: Tour) {
    try {
      const { data } = await axios.post(`${this.url}/admin/api/tours`, tour, {
        headers: {
          authorization: `Bearer ${tokenManagement.getAuthToken()}`,
        },
      });

      return data;
    } catch {
      return {};
    }
  }

  async updateTour(tour: Tour) {
    try {
      const { data } = await axios.put(
        `${this.url}/admin/api/tours/${tour.id}`,
        tour,
        {
          headers: {
            authorization: `Bearer ${tokenManagement.getAuthToken()}`,
          },
        }
      );

      return data;
    } catch {
      return {};
    }
  }
}

export const restAdminClient = new AdminAPI(
  process.env.REACT_APP_SERVER_API || "http://localhost:8080"
);
