import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { restAdminClient } from "../../../api/admin.api";
import { tokenManagement } from "../../../auth/token";
import { Tour } from "../../../entities/tour";

import { BasePrivate } from "../../basePrivate";

const isValidTour = (tour: string) => {
  try {
    const parsedTour = JSON.parse(tour) as Partial<Tour>;
    return parsedTour.name?.length && parsedTour.cityId?.length;
  } catch {
    return false;
  }
};

export const AdminImportTours = () => {
  const [tourContent, setTourContent] = useState<string>(
    JSON.stringify({
      name: "",
      price: {
        currency: "EUR",
        price: "0",
      },
      languagesCode: ["EN", "ES"],
      defaultLanguageCode: "ES",
      tourDetails: [
        {
          languageCode: "EN",
          name: "",
          description: "",
        },
        {
          languageCode: "ES",
          name: "",
          description: "",
        },
      ],
      tourDistance: "",
      tourDuration: "",
      tags: ["Walk Tour", "Free"],
      cityId: "",
      pointsOfInterest: [],
      routePoints: [],
      stopPoints: [],
    })
  );

  const navigate = useNavigate();

  if (!tokenManagement.isAdmin()) {
    navigate("/dashboard");
  }

  const onChangeTourContent = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setTourContent(evt.currentTarget.value);
  };

  const createTour = () => {
    try {
      const parsedTour = JSON.parse(tourContent) as Tour;
      restAdminClient.createTour(parsedTour);
    } catch {
      alert("Invalid format!");
    }
  };

  const updateTour = () => {
    try {
      const parsedTour = JSON.parse(tourContent) as Tour;
      restAdminClient.updateTour(parsedTour);
    } catch {
      alert("Invalid format!");
    }
  };

  const isUpdate = () => {
    try {
      const parsedTour = JSON.parse(tourContent);
      return !!parsedTour.id;
    } catch {
      return false;
    }
  };

  return (
    <BasePrivate title="Users (Admin)">
      <Stack height="100%" paddingRight={4}>
        <Box flex={1} display="flex" flexDirection="column">
          <Heading size="sm" textTransform="uppercase">
            Tour
          </Heading>
          <Textarea
            onChange={onChangeTourContent}
            value={tourContent}
            flex="auto"
          />
          <HStack justifyContent="flex-end">
            {isUpdate() ? (
              <Button disabled={!isValidTour(tourContent)} onClick={updateTour}>
                Update Tour
              </Button>
            ) : (
              <Button disabled={!isValidTour(tourContent)} onClick={createTour}>
                Create Tour
              </Button>
            )}
          </HStack>
        </Box>
      </Stack>
    </BasePrivate>
  );
};
