export const gqlToursQuery = `
  query Tours {
    tours {
      id
      name
      externalUrlTour
      status
      cityId
      authorId
      ownerId
      city {
        name
        location {
          lat
          lng
        }
      }
    }
  }
`;

export const gqlTourByIdQuery = `
  query Tour($tourId: String!) {
    tour(id: $tourId) {
      id
      name
      externalUrlTour
      authorId
      price {
        currency
        price
      }
      offerPrice {
        currency
        price
      }
      discount
      cityId
      city {
        name
        location {
          lat
          lng
        }
      }
      status
      screenshotMap
      languagesCode
      tourDetails {
        name
        description
        languageCode
        images
        introductionVideo
      }
      tourDuration
      tags
      routePoints {
        name
        type
        location {
          lat
          lng
        }
        icon
        languages {
          description
          languageCode
          audio
          videos
          images
        }
      }
      stopPoints {
        name
        type
        location {
          lat
          lng
        }
        icon
        languages {
          description
          languageCode
          audio
          videos
          images
        }
      }
      pointsOfInterest {
        name
        type
        location {
          lat
          lng
        }
        icon
        languages {
          description
          languageCode
          audio
          videos
          images
        }
      }
      stats {
        pending
        played
        completed
        cancelled
        sales
        cancelledSales
      }
      score
      totalVotes
      totalVisits
      createdAt
      updatedAt
    }
  }
`;
