import jwtDecode from "jwt-decode";
import { LS_TOKEN } from "../config";

interface TokenData {
  user: string;
  name: string;
  email: string;
  role: UserTypes;
}

type UserTypes = "SUPERADMIN" | "AUTHOR" | "USER";
const UserType = {
  SUPERADMIN: "SUPERADMIN",
  AUTHOR: "AUTHOR",
  USER: "USER",
};

export const tokenManagement = {
  getAuthContext: () => {
    const token = localStorage.getItem(LS_TOKEN);

    return {
      token: token || undefined,
    };
  },

  getTokenContent: () => {
    const token = localStorage.getItem(LS_TOKEN);

    if (token) {
      const decodedToken: TokenData = jwtDecode(token);
      return {
        user: decodedToken.user.split("").reverse().join(""),
        name: decodedToken.name,
        email: decodedToken.email,
      };
    }
    return {};
  },

  isAdmin: () => {
    const token = localStorage.getItem(LS_TOKEN);

    if (token) {
      const decodedToken: TokenData = jwtDecode(token);
      return decodedToken.role === UserType.SUPERADMIN;
    }
    return {};
  },

  setAuthToken: (token: string) => {
    localStorage.setItem(LS_TOKEN, token);
  },

  removeAuthToken: () => {
    localStorage.removeItem(LS_TOKEN);
  },

  getAuthToken: () => {
    return localStorage.getItem(LS_TOKEN);
  },
};
