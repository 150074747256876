import { LatLng } from "leaflet";
import { ReactNode } from "react";
import { Polyline } from "react-leaflet";
import { PointDetails } from "../../../../../entities/pointDetails";
import { RouteDotMarker } from "./routeDotMarker";

const routeStyle = {
  color: "#E53E3E88",
  weight: 6,
  dashArray: "1, 10",
  // lineCap: "square",
};

interface RouteDrawerProps {
  dataPoints: PointDetails[];
  movingRoutePoint: number | null;
  setInsertAfterThis: (x: number) => void;
  insertAfterThis: number | null;
  showLabels: boolean;
}

export const RouteDrawer = ({
  dataPoints,
  movingRoutePoint,
  insertAfterThis,
  setInsertAfterThis,
  showLabels,
}: RouteDrawerProps) => {
  const routeComponents: { points: LatLng[]; circles: ReactNode[] } = dataPoints
    .filter((dataPoint: PointDetails) => dataPoint.location?.lat)
    .reduce(
      (acc: any, dataPoint: PointDetails, index: number) => {
        const newLatLng = new LatLng(
          parseFloat(dataPoint.location!.lat),
          parseFloat(dataPoint.location!.lng)
        );

        acc.points.push(newLatLng);
        acc.circles.push(
          <RouteDotMarker
            key={dataPoint.location!.lat + dataPoint.location!.lng}
            type={dataPoint.type}
            focus={movingRoutePoint === index}
            reference={insertAfterThis === index}
            latlng={newLatLng}
            initialPoint={index === 0}
            lastPoint={index === dataPoints.length - 1}
            index={index}
            setInsertAfterThis={setInsertAfterThis}
            showLabels={showLabels}
          />
        );
        return acc;
      },
      { points: [], circles: [] }
    );

  return (
    <>
      {!movingRoutePoint && (
        <Polyline
          pathOptions={{
            ...routeStyle,
            color: insertAfterThis ? "#E53E3E33" : routeStyle.color,
          }}
          positions={routeComponents.points}
        />
      )}
      {routeComponents.circles}
    </>
  );
};
