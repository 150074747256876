import { ChangeEvent } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Highlight,
  HStack,
  Input,
  Tooltip,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import { FaInfoCircle } from "react-icons/fa";
import { capitalizeFirstLetter } from "../../utils/strings";
import { DEFAULT_LANGUAGE_CODE } from "../../pages/tour/details";

interface MultiSwitchFieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  description?: string;
  min?: string;
  max?: string;
  stringSeparator?: string;
  options?: string[];
}

export const MultiSwitchField = ({
  name,
  label,
  type,
  required,
  description,
  stringSeparator = ";",
  options,
  ...props
}: MultiSwitchFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const drawHelper = () =>
    description && (
      <Box display="inline-block" marginRight="4px" position="relative">
        <Tooltip hasArrow label={description} bg="gray.300" color="black">
          <span>
            <FaInfoCircle
              style={{
                cursor: "pointer",
              }}
            />
          </span>
        </Tooltip>
      </Box>
    );

  const drawRequired = () =>
    required && (
      <Highlight
        query="(required)"
        styles={{ px: "1", py: "1", bg: "orange.100", fontSize: "10px" }}
      >
        (required)
      </Highlight>
    );

  const changeSwitch =
    (opt: string) => (event: ChangeEvent<HTMLInputElement>) => {
      let newSelectedSwitch = field.value;
      if (event.target.checked) {
        if (field.value.length) {
          newSelectedSwitch = field.value + stringSeparator;
        }

        newSelectedSwitch += opt;
      } else {
        const fields = (field.value || DEFAULT_LANGUAGE_CODE).split(
          stringSeparator
        );
        if (fields.length > 1) {
          newSelectedSwitch = fields
            .filter(
              (option: string) => option.toUpperCase() !== opt.toUpperCase()
            )
            .join(stringSeparator);
        }
      }
      helpers.setValue(newSelectedSwitch);
    };

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <FormLabel htmlFor={field.name} style={{ position: "relative" }}>
        {drawHelper()}
        {`${label}`}
        {drawRequired()}
      </FormLabel>
      <Input
        id={name}
        {...field}
        type={type || "text"}
        {...props}
        display="none"
      />
      <HStack spacing={1} paddingTop={2} paddingBottom={2}>
        {(options || []).map((opt: string) => (
          <HStack alignItems="center" key={opt}>
            <Text fontSize="sm">{capitalizeFirstLetter(opt)}:</Text>
            <Switch
              size="sm"
              key={opt}
              padding={1}
              isChecked={field.value.toUpperCase().includes(opt.toUpperCase())}
              borderRadius="full"
              variant="solid"
              colorScheme="gray"
              onChange={changeSwitch(opt)}
            />
          </HStack>
        ))}
      </HStack>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
