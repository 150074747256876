import {
  Button,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { ContentEditableEvent, DefaultEditor } from "react-simple-wysiwyg";
import { useRecoilValue } from "recoil";
import { restClient } from "../../../../../api/rest.api";
import { MediaUpload } from "../../../../../components/mediaUpload";
import {
  LanguageDetails,
  PointDetails,
} from "../../../../../entities/pointDetails";
import { Language } from "../../../../../entities/reference";
import { availableIcons } from "../../../../../icons";

import {
  DEFAULT_POINT_TYPE,
  typePoiDropdownOptions,
  typeRoutePointDropdownOptions,
} from "../../../../../schemas/pointDetails";
import { fullTour } from "../../../../../state/fullTour";
import { referenceData } from "../../../../../state/reference";
import { capitalizeFirstLetter } from "../../../../../utils/strings";
import { DEFAULT_LANGUAGE_CODE } from "../../../details";

interface PointDetailsPopupProps {
  pointDetails: PointDetails;
  isOpen: boolean;
  blockedStatus: boolean;
  type: "route" | "poi" | "stop";
  onClose: () => void;
  saveEditPoint: (pointDetails: PointDetails) => void;
  onRemovePoint: () => void;
  index: number;
}

const MediaAttribute: { [key: string]: "audio" | "videos" | "images" } = {
  audio: "audio",
  video: "videos",
  image: "images",
};

export const PointDetailsPopup = ({
  isOpen,
  onClose,
  blockedStatus,
  type,
  saveEditPoint,
  onRemovePoint,
  pointDetails,
  index,
}: PointDetailsPopupProps) => {
  const isRoute = type === "route";
  const isStop = type === "stop";
  const isPoi = type === "poi";
  const fullTourValue = useRecoilValue(fullTour);
  const { languages } = useRecoilValue(referenceData);
  const [pointDetailsStatus, setPointDetailsStatus] = useState(pointDetails);

  const onClickSave = () => {
    saveEditPoint(pointDetailsStatus);
    onClose();
  };

  const onChangeField =
    (field: "name" | "type" | "icon") =>
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newPointDetailsStatus = structuredClone(pointDetailsStatus);
      newPointDetailsStatus[field] = e.target.value;
      setPointDetailsStatus(newPointDetailsStatus);
    };

  const getFromLanguage = (
    lang: string,
    field: "name" | "description" | "images" | "videos" | "audio",
    defaultValue: string | string[] = ""
  ) => {
    const fieldValue = ((pointDetailsStatus.languages || []).find(
      ({ languageCode }: LanguageDetails) => lang === languageCode
    ) || {})[field];
    return fieldValue?.length ? fieldValue : defaultValue;
  };

  const setLanguageField =
    (lang: string, field: "description" | "name") =>
    (e: ContentEditableEvent) => {
      const newPointDetailsStatus = structuredClone(pointDetailsStatus);
      let foundLanguage = false;
      newPointDetailsStatus.languages = (
        newPointDetailsStatus.languages || []
      ).map((language: LanguageDetails) => {
        if (language.languageCode === lang) {
          foundLanguage = true;
          return {
            ...language,
            [field]: e.target.value,
          };
        }
        return language;
      });

      if (!foundLanguage) {
        newPointDetailsStatus.languages.push({
          languageCode: lang,
          [field]: e.target.value,
        });
      }
      setPointDetailsStatus(newPointDetailsStatus);
    };

  const addLanguageMedia = (
    lang: string,
    field: "images" | "videos" | "audio",
    filename: string
  ) => {
    const newPointDetailsStatus = structuredClone(pointDetailsStatus);
    let foundLanguage = false;
    newPointDetailsStatus.languages = (
      newPointDetailsStatus.languages || []
    ).map((language: LanguageDetails) => {
      if (language.languageCode === lang) {
        foundLanguage = true;
        return {
          ...language,
          [field]:
            field !== "audio"
              ? [...structuredClone(language[field] || []), filename]
              : filename,
        };
      }
      return language;
    });

    if (!foundLanguage) {
      newPointDetailsStatus.languages.push({
        languageCode: lang,
        [field]: field !== "audio" ? [filename] : filename,
      });
    }

    setPointDetailsStatus(newPointDetailsStatus);
    saveEditPoint(newPointDetailsStatus);
  };

  const removeLanguageMedia = (
    lang: string,
    field: "images" | "videos" | "audio",
    filename: string
  ) => {
    const newPointDetailsStatus = structuredClone(pointDetailsStatus);
    let foundLanguage = false;
    newPointDetailsStatus.languages = (
      newPointDetailsStatus.languages || []
    ).map((language: LanguageDetails) => {
      if (language.languageCode === lang) {
        foundLanguage = true;
        return {
          ...language,
          [field]:
            field !== "audio"
              ? structuredClone(language[field] || []).filter(
                  (mediaName: string) => mediaName !== filename
                )
              : "",
        };
      }
      return language;
    });

    if (!foundLanguage) {
      newPointDetailsStatus.languages.push({
        languageCode: lang,
        [field]: field !== "audio" ? [] : "",
      });
    }
    setPointDetailsStatus(newPointDetailsStatus);
    saveEditPoint(newPointDetailsStatus);
  };

  const uploadMedia =
    (languageCode: string, mediaType: "video" | "image" | "audio") =>
    (
      files: File[],
      callback: (file: File, filename: string, succeed: boolean) => void
    ) => {
      restClient.uploadMedia(
        fullTourValue.id || "",
        files,
        {
          location: `tour.${
            type === "route" ? "routePoint" : "pointOfInterest"
          }.${mediaType}`,
          languageCode: languageCode,
          index,
        },
        (file: File, filename: string, succeed: boolean) => {
          if (succeed) {
            addLanguageMedia(languageCode, MediaAttribute[mediaType], filename);
          }
          callback(file, filename, succeed);
        }
      );
    };

  const removeMedia =
    (languageCode: string, mediaType: "video" | "image" | "audio") =>
    (
      filename: string,
      callback: (filename: string, succeed: boolean) => void
    ) => {
      restClient.deleteMedia(
        fullTourValue.id || "",
        filename,
        {
          location: `tour.${
            type === "route" ? "routePoint" : "pointOfInterest"
          }.${mediaType}`,
          languageCode: languageCode,
          index,
        },
        (filename: string, succeed: boolean) => {
          if (succeed) {
            removeLanguageMedia(
              languageCode,
              MediaAttribute[mediaType],
              filename
            );
          }
          callback(filename, succeed);
        }
      );
    };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Point</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>Type:</FormLabel>
          {isStop && (
            <Select
              value={pointDetailsStatus.type || DEFAULT_POINT_TYPE}
              onChange={onChangeField("type")}
            >
              {typeRoutePointDropdownOptions.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          )}
          {isPoi && (
            <Select
              value={pointDetailsStatus.type || typePoiDropdownOptions[0].id}
              onChange={onChangeField("type")}
            >
              {typePoiDropdownOptions.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          )}
          {isPoi && (
            <>
              <FormLabel>Icon:</FormLabel>
              <Select
                value={pointDetailsStatus.icon || availableIcons[0].id}
                onChange={onChangeField("icon")}
              >
                {availableIcons.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </Select>
            </>
          )}
          <FormLabel>Name:</FormLabel>
          <Input
            value={pointDetailsStatus.name || ""}
            onChange={onChangeField("name")}
          />
          {/* Images */}
          {!isRoute && (
            <Tabs flex="auto" marginTop={4} alignSelf="stretch">
              <Text>Translations</Text>
              <TabList>
                {(fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE]).map(
                  (lang: string) => (
                    <Tab key={lang}>
                      {capitalizeFirstLetter(
                        languages.find(
                          (l: Language) => l.code === lang.toUpperCase()
                        )?.name || lang
                      )}
                    </Tab>
                  )
                )}
              </TabList>

              <TabPanels>
                {(fullTourValue.languagesCode || [DEFAULT_LANGUAGE_CODE]).map(
                  (lang: string) => (
                    <TabPanel key={lang}>
                      <FormLabel>Name:</FormLabel>
                      <Input
                        value={getFromLanguage(lang, "name") as string}
                        onChange={setLanguageField(lang, "name")}
                      />
                      <FormLabel>Description:</FormLabel>
                      <DefaultEditor
                        value={getFromLanguage(lang, "description") as string}
                        onChange={setLanguageField(lang, "description")}
                      />
                      <MediaUpload
                        label="Images"
                        multiple={true}
                        files={getFromLanguage(lang, "images", []) as string[]}
                        uploadFiles={uploadMedia(lang, "image")}
                        onRemoveFile={removeMedia(lang, "image")}
                      />
                      <MediaUpload
                        label="Videos"
                        multiple={true}
                        files={getFromLanguage(lang, "videos", []) as string[]}
                        type="video"
                        uploadFiles={uploadMedia(lang, "video")}
                        onRemoveFile={removeMedia(lang, "video")}
                      />
                      <MediaUpload
                        label="Audio"
                        multiple={false}
                        files={[
                          getFromLanguage(lang, "audio", "") as string,
                        ].filter((val: string) => val.length)}
                        type="audio"
                        uploadFiles={uploadMedia(lang, "audio")}
                        onRemoveFile={removeMedia(lang, "audio")}
                      />
                    </TabPanel>
                  )
                )}
              </TabPanels>
            </Tabs>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onClickSave}
            disabled={blockedStatus}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <IconButton
            icon={<FaTrash />}
            aria-label="Remove"
            colorScheme="red"
            onClick={onRemovePoint}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
