import { Button, List, ListIcon, ListItem } from "@chakra-ui/react";
import { FaCamera, FaEye, FaMapPin } from "react-icons/fa";

interface ContextMenuProps {
  left: string;
  top: string;
  selectedRoutePoint: number | null;
  selectedStopPoint: number | null;
  showScreenshotMask: boolean;
  setShowScreenshotMask: (value: boolean) => void;
  moveHereRoutePoint?: () => void;
  moveHereStopPoint?: () => void;
  onClose: () => void;
  onRouteAddClick: () => void;
  onStopAddClick: () => void;
  onPoiAddClick: () => void;
  onAddRouteFromLinkClick: () => void;
  onAddRouteFromCoordinatesClick: () => void;
}

const buttonStyle = {
  background: "transparent",
  width: "100%",
  justifyContent: "flex-start",
  _hover: { bg: "#ffffffcc" },
};

export const ContextMenu = ({
  left,
  top,
  selectedRoutePoint,
  selectedStopPoint,
  showScreenshotMask,
  setShowScreenshotMask,
  moveHereRoutePoint,
  moveHereStopPoint,
  onClose,
  onRouteAddClick,
  onStopAddClick,
  onPoiAddClick,
  onAddRouteFromLinkClick,
  onAddRouteFromCoordinatesClick,
}: ContextMenuProps) => {
  return (
    <List
      spacing={3}
      position="absolute"
      left={left}
      top={top}
      zIndex={999}
      onMouseLeave={onClose}
      background="#ffffffaa"
      textAlign="left"
    >
      {selectedRoutePoint !== undefined && selectedRoutePoint !== null && (
        <ListItem>
          <Button {...buttonStyle} onClick={moveHereRoutePoint}>
            <ListIcon as={FaMapPin} color="green.500" />
            Move Route Point Here
          </Button>
        </ListItem>
      )}
      {selectedStopPoint !== undefined && selectedStopPoint !== null && (
        <ListItem>
          <Button {...buttonStyle} onClick={moveHereStopPoint}>
            <ListIcon as={FaMapPin} color="green.500" />
            Move Stop Point Here
          </Button>
        </ListItem>
      )}
      <ListItem>
        <Button {...buttonStyle} onClick={onRouteAddClick}>
          <ListIcon as={FaMapPin} color="green.500" />
          Add Route Point
        </Button>
      </ListItem>
      <ListItem>
        <Button {...buttonStyle} onClick={onStopAddClick}>
          <ListIcon as={FaMapPin} color="green.500" />
          Add Stop Point
        </Button>
      </ListItem>
      <ListItem>
        <Button {...buttonStyle} onClick={onPoiAddClick}>
          <ListIcon as={FaEye} color="green.500" />
          Add Point of Interest
        </Button>
      </ListItem>
      <ListItem>
        <Button {...buttonStyle} onClick={onAddRouteFromLinkClick}>
          <ListIcon as={FaEye} color="green.500" />
          Add Point from Link
        </Button>
      </ListItem>
      <ListItem>
        <Button {...buttonStyle} onClick={onAddRouteFromCoordinatesClick}>
          <ListIcon as={FaEye} color="green.500" />
          Add Point from Coordinates
        </Button>
      </ListItem>
      <ListItem>
        <Button
          {...buttonStyle}
          onClick={() => setShowScreenshotMask(!showScreenshotMask)}
        >
          <ListIcon as={FaCamera} color="green.500" />
          {showScreenshotMask ? "Hide Screenshot Mask" : "Show ScreenshotMask"}
        </Button>
      </ListItem>
    </List>
  );
};
