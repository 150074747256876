import { createContext, useContext } from "react";

interface IAuthContext {
  token?: string;
  name?: string;
}

export const AuthContext = createContext<IAuthContext>({});

export const useAuth = () => {
  return useContext(AuthContext);
};
