import {
  LanguageDetails,
  PointDetails,
} from "../../../../entities/pointDetails";

export const mapDataPoints = (dataPoints: PointDetails[]) => {
  return (
    dataPoints.map(
      ({ type, name, location, languages, icon }: PointDetails) => ({
        name,
        type,
        location: {
          lat: location.lat,
          lng: location.lng,
        },
        languages: (languages || []).map(
          ({
            languageCode,
            name,
            description,
            images,
            videos,
            audio,
          }: LanguageDetails) => ({
            name,
            description,
            languageCode,
            images,
            videos,
            audio: audio || "",
          })
        ),
        icon,
      })
    ) || []
  );
};
