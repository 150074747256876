import L from "leaflet";

export const iconFactory = (iconUrl: string, iconRetinaUrl?: string) =>
  new L.Icon({
    iconUrl,
    iconRetinaUrl: iconRetinaUrl || iconUrl,
    // iconAnchor: null,
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null,
    iconSize: new L.Point(40, 40),
    className: "",
  });
