const KILO_BYTES_PER_BYTE = 1024;

export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = {
  image: 500000,
  video: 52500000,
  audio: 1000000,
};

export const convertBytesToKB = (bytes: number) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE);

export const getMediaURL = (filename: string) => {
  return `${
    process.env.REACT_APP_SERVER_API || "http://localhost:8080"
  }/media-files/${filename}`;
};
