import ReactPlayer from "react-player";
import { MediaWrapper, MediaWrapperProps } from "./wrapper";

export const MediaVideo = ({
  name,
  src,
  loading,
  onClickRemove,
}: MediaWrapperProps) => {
  return (
    <MediaWrapper
      name={name}
      src={src}
      loading={loading}
      onClickRemove={onClickRemove}
    >
      <ReactPlayer
        url={src}
        alt={name}
        width="200px"
        height="200px"
        controls={true}
        light={true}
      />
    </MediaWrapper>
  );
};
