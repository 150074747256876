import * as yup from "yup";

const passwordSecurityPattern =
  /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

const passwordNoSpaceAndLength = /^\S{8,}$/;

export const userSchema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  password: yup
    .string()
    .matches(passwordSecurityPattern, {
      excludeEmptyString: true,
      message:
        "Your password should include at least 1 capital letter, 1 lowercase letter and 1 number or special character.",
    })
    .matches(passwordNoSpaceAndLength, {
      excludeEmptyString: true,
      message:
        "Your password should have at least 8 characters and no spaces allowed",
    })
    .optional(),
  password2: yup.string().when("password", {
    is: (pass: string) => !!pass?.length,
    then: (schema) => schema.required("Confirm your password"),
    otherwise: (schema) => schema.optional(),
  }),
  tags: yup.string(),
  languagesCode: yup.string(),
});
